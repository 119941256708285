@import "./../../stylesheets/_definitions";

body {
  @include resetSpaces;
  border: 0 none;
  font-family: "Open Sans", sans-serif;
  font-size: $text-size;
}

h1,
h2,
p {
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

.Hr {
  @include generate-theme {
    border: theme-var(border-lighter);
  }
  @include setMargin(getMargin($mode: small));

  &--dashed {
    @extend .Hr;
    @include generate-theme {
      border: theme-var(border-non-solid-light);
    }
  }
}

.material-icons {
  vertical-align: middle;
}

.form-control {
  @include setInputStyle;
}

.input-group-addon {
  @include setInputDecoratorsStyle;
}

.FormData__textarea-buttons
  > .input-group-btn
  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: getBorderRadius();
  border-bottom-left-radius: getBorderRadius();
}

.FormData__textarea-buttons
  > .input-group-btn
  > .btn:last-child:not(:first-child):not(.dropdown-toggle) {
  border-top-right-radius: getBorderRadius();
  border-bottom-right-radius: getBorderRadius();
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

// Tooltip
@each $theme, $map in $themes {
  $theme-name: $theme !global;
  .Tooltip--#{$theme-name} {
    & > .tooltip-inner {
      @include setBackgroundColor(theme-var(tooltip-background-color));
      color: theme-var(tooltip-text-color);
      max-height: 70px;
      overflow: hidden;
    }
  }
}

.Tooltip {
  & > .tooltip-inner {
    @include setBackgroundColor($mapTheme__background-color--lightest);
    color: $mapTheme__text-color;
    max-height: 70px;
    overflow: hidden;
  }
}

.bs-tooltip-top .arrow::before {
  border-top-color: $mapTheme__background-color--lightest !important;
}

.bs-tooltip-right .arrow::before {
  border-right-color: $mapTheme__background-color--lightest !important;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $mapTheme__background-color--lightest !important;
}

.bs-tooltip-left .arrow::before {
  border-left-color: $mapTheme__background-color--lightest !important;
}

// Avatar
.Avatar__image {
  @include setBorderRadius;
}

.Avatar__icon {
  @include generate-theme {
    @include setBackgroundColor(theme-var(background-color-lightest));
  }
  @include setBorderRadius;
  top: 0px;
  overflow: hidden;
}

// Captcha
@each $theme, $map in $themes {
  $theme-name: $theme !global;
  .Captcha--#{$theme-name} {
    & .modal-dialog {
      border: theme-var(border);
      @include setBorderRadius;
    }

    & .Captcha__body {
      @include setBackgroundColor(theme-var(background-color));
    }

    & .Captcha__footer {
      @include setBackgroundColor(theme-var(background-color));
      border-top: theme-var(border-lighter);
    }

    & .Captcha__cancel {
      @include resetShadow;
      @include resetBackground;
      @include setBackgroundColor(theme-var(button-background-color));
      color: theme-var(button-text-color);
      border: theme-var(button-border);

      &:hover,
      &:active,
      &:focus,
      &:hover:active,
      &:active:focus,
      &:hover:active:focus {
        @include setBackgroundColor(theme-var(button-background-color));
        color: theme-var(button-action-text-color);
        border: theme-var(visible-button-action-border);
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus {
        @include setBackgroundColor(theme-var(button-background-color));
        color: theme-var(button-disabled-text-color);
        border: theme-var(visible-button-disabled-border);
      }
    }
  }
}

@keyframes fullRotation {
  @include getFullRotationKeyframes;
}

@keyframes pulsing-circle {
  @include getPulsingKeyframes(
    $initialSize: $pulsingIconCircleInitialSize,
    $finalSize: $pulsingIconCircleFinalSize,
    $finalTop: $pulsingIconCircleFinalTop,
    $finalLeft: $pulsingIconCircleFinalLeft
  );
}
@keyframes pulsing-triangle {
  @include getPulsingKeyframes(
    $initialSize: $pulsingIconTriangleInitialSize,
    $finalSize: $pulsingIconTriangleFinalSize,
    $finalTop: $pulsingIconTriangleFinalTop,
    $finalLeft: $pulsingIconTriangleFinalLeft
  );
}
@keyframes pulsing-square {
  @include getPulsingKeyframes(
    $initialSize: $pulsingIconSquareInitialSize,
    $finalSize: $pulsingIconSquareFinalSize,
    $finalTop: $pulsingIconSquareFinalTop,
    $finalLeft: $pulsingIconSquareFinalLeft
  );
}
@keyframes pulsing-diamond {
  @include getPulsingKeyframes(
    $initialSize: $pulsingIconDiamondInitialSize,
    $finalSize: $pulsingIconDiamondFinalSize,
    $finalTop: $pulsingIconDiamondFinalTop,
    $finalLeft: $pulsingIconDiamondFinalLeft
  );
}
@keyframes widthAnimationFromUnitTypesToInfo {
  @include getWidthAnimationKeyframes(
    $modalSection__infoUnitTypesWidth,
    $modalSection__infoWidth
  );
}
@keyframes widthAnimationFromInfoToUnitTypes {
  @include getWidthAnimationKeyframes(
    $modalSection__infoWidth,
    $modalSection__infoUnitTypesWidth
  );
}
@keyframes widthAnimationFromInfoToBoard {
  @include getWidthAnimationKeyframes(
    $modalSection__infoWidth,
    $modalSection__infoBoardWidth
  );
}
@keyframes widthAnimationFromBoardToInfo {
  @include getWidthAnimationKeyframes(
    $modalSection__infoBoardWidth,
    $modalSection__infoWidth
  );
}
@keyframes collapseLeftMenuAnimation {
  @include getWidthAnimationKeyframes($leftSection_width, 0px);
}
@keyframes expandLeftMenuAnimation {
  @include getWidthAnimationKeyframes(0px, $leftSection_width);
}
@keyframes narrowBottomSectionAnimation {
  @include getLeftAnimationKeyframes(0px, $leftSection_width);
}
@keyframes extendBottomSectionAnimation {
  @include getLeftAnimationKeyframes($leftSection_width, 0px);
}
@keyframes narrowModalAnimation {
  @include getLeftAnimationKeyframes(0px, $leftSection_width);
}
@keyframes expandModalAnimation {
  @include getLeftAnimationKeyframes($leftSection_width, 0px);
}
@keyframes narrowCookieInfoAnimation {
  @include getLeftAnimationKeyframes(
    $cookieInfo__verticalMargin,
    $cookieInfo__verticalMargin + $leftSection_width
  );
}
@keyframes expandCookieInfoAnimation {
  @include getLeftAnimationKeyframes(
    $cookieInfo__verticalMargin + $leftSection_width,
    $cookieInfo__verticalMargin
  );
}
@keyframes narrowAgentButtonAnimation {
  @include getLeftAnimationKeyframes(
    $agentButton__verticalMargin,
    $agentButton__verticalMargin + $leftSection_width
  );
}
@keyframes expandAgentButtonAnimation {
  @include getLeftAnimationKeyframes(
    $agentButton__verticalMargin + $leftSection_width,
    $agentButton__verticalMargin
  );
}
@keyframes lightenLogoAnimationMap {
  @include getLightenLogoAnimationKeyframes(
    $finalShadow: 0px 0px $mapContainer__shadowRadiusSize
      $mapTheme__map-background-color--light,
    $finalBackground: rgba($mapTheme__map-background-color, 0.32)
  );
}
@keyframes lightenLogoAnimationSatellite {
  @include getLightenLogoAnimationKeyframes(
    $finalShadow: 0px 0px $mapContainer__shadowRadiusSize
      $satelliteTheme__map-background-color,
    $finalBackground: rgba($satelliteTheme__map-background-color, 0.32)
  );
}
@keyframes darkenLogoAnimationMap {
  @include getLightenLogoAnimationKeyframes(
    $initialShadow: 0px 0px $mapContainer__shadowRadiusSize
      $mapTheme__map-background-color--light,
    $initialBackground: rgba($mapTheme__map-background-color, 0.32)
  );
}
@keyframes darkenLogoAnimationSatellite {
  @include getLightenLogoAnimationKeyframes(
    $initialShadow: 0px 0px $mapContainer__shadowRadiusSize
      $satelliteTheme__map-background-color,
    $initialBackground: rgba($satelliteTheme__map-background-color, 0.32)
  );
}
