@import "./../../stylesheets/_definitions";

.TopSection {
  @include resetPadding;
  @include setOpacity(0.99);
  height: $topSection__height;
  display: table;
  width: 100%;

  &__component-space {
    display: table-cell;
    width: 33%;
    text-align: center;
    vertical-align: middle;

    &--left {
      @extend .TopSection__component-space;
      text-align: left;
      @include setPadding(0px, $left: $horizontalPadding);
    }

    &--right {
      @extend .TopSection__component-space;
      text-align: right;
      @include setPadding(0px, $right: $horizontalPadding);
    }
  }

  &__logo {
    width: 100px;
    height: 26px;
  }
}
