@import "./../../stylesheets/_definitions";

.SelectedPoint--circle {
  @include getPulsingAnimation("pulsing-circle");
}

.SelectedPoint--square {
  @include getPulsingAnimation("pulsing-square");
}

.SelectedPoint--diamond {
  @include getPulsingAnimation("pulsing-diamond");
}

.SelectedPoint--triangle {
  @include getPulsingAnimation("pulsing-triangle");
}
