@import "./../../stylesheets/_definitions";

.MessengerMoreMessages {
  text-align: center;
  @include setPadding(0px, $horizontal: getPadding());
  @include generate-theme {
    color: theme-var(text-color-lighter);
  }
  font-size: $text-size--small;
}
