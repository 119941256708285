@import "./../../stylesheets/_definitions";

.CookieInfo {
  $verticalMargin: $cookieInfo__verticalMargin;

  @include generate-theme {
    @include setBackgroundOpacity(
      theme-var(opacity),
      theme-var(background-color)
    );
    color: theme-var(text-color);
    border: theme-var(border);
  }
  @include setPadding(2 * getPadding() 4 * getPadding());
  @include setBorderRadius;
  @include getCookieInfoLeftMenuAnimation(expand);
  position: absolute;
  bottom: $cookieInfo__bottomMargin;
  left: $verticalMargin;
  right: $verticalMargin;

  &--narrow {
    @extend .CookieInfo;
    @include getCookieInfoLeftMenuAnimation(narrow);
    left: $verticalMargin + $leftSection_width;
  }

  &--partial30 {
    bottom: 30% + $cookieInfo__bottomMargin;
  }

  &__info-wrapper {
    position: relative;
  }

  &__text-button {
    @include resetButtonDecorations;
  }

  &__dismiss-button-wrapper {
    text-align: right;
  }

  &__dismiss-button {
    @include setVisibleButton;
  }
}
