@import "./../../stylesheets/_definitions";

.LoginRegisterButton {
  @include setMargin(0px, $top: -5px);
  display: inline-block;
  vertical-align: middle;

  &__button {
    @include setVisibleButton;
    @include generate-theme {
      @include setOpacity(theme-var(opacity));
    }
    @include setPadding(0px, $horizontal: 2 * getPadding());
    height: 20px;
    font-size: 12px;
  }
}
