@import "./../../stylesheets/_definitions";

$thumbnailSize: 60px;

.MessengerListTopic {
  display: table;
  @include setPadding();
  @include setPointerCursor;
  width: 100%;

  &--selected {
    @extend .MessengerListTopic;
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-selection-color));
    }
  }

  &:hover {
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-action-color));
    }
  }

  &__thumbnail-wrapper {
    display: table-cell;
    width: $thumbnailSize;
    height: $thumbnailSize;
    vertical-align: top;
    @include setPadding(0px, $horizontal: getPadding());
  }

  &__thumbnail {
    font-size: $thumbnailSize + 1;
  }

  &__right-content {
    @include setPadding(0px, $horizontal: getPadding());
    display: table-cell;
    vertical-align: top;
  }

  &__user-table {
    display: table;
    width: 100%;
  }

  &__user {
    display: table-cell;
    font-size: $text-size--big;
  }

  &__date {
    display: table-cell;
    text-align: right;
    @include generate-theme {
      color: theme-var(color--light);
    }
  }

  &__message {
    @include generate-theme {
      color: theme-var(color--light);
    }
    max-height: 37px;
    overflow: hidden;
    font-size: $text-size--small;
  }
}
