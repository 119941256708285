@import "./../../stylesheets/_definitions";

.MessageButton {
  @include setMargin(0px, $right: $topSection__iconsPadding);
  display: inline-block;
  position: relative;

  &__button {
    @include setMapButton($fontSize: $topSection__iconsSize);
    vertical-align: sub;
  }
  &__button-icon {
    font-size: 36px;
  }

  &__unreadAmount {
    background-color: red;
    position: absolute;
    padding: 1px 5px;
    border-radius: 100%;
    font-size: 11px;
    top: 10px;
    right: -3px;
  }
}
