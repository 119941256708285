@import "./../../stylesheets/_definitions";

.HowToThumbnail {
  $width: 189px;
  $height: 107px;
  $thumbnailUrl: "/img/howToPreviewThumbnail.jpg";

  //@include setModalSectionStyle;
  @include resetPadding;
  @include aboveMapTilePane;
  position: absolute;
  right: $horizontalPadding;
  top: $videoModal__topPosition;
  width: $width;
  height: $height;
  overflow: hidden;

  &__button {
    @include resetButtonDecorations($padding: getPadding());
    @include aboveMapTilePane;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  &__thumbnail {
    @include resetBorder;
    @include resetButtonDecorations;
    @include setPadding($left: 11px);
    width: $width;
    height: $height;
    background: url($thumbnailUrl);
    background-size: $width $height;

    &:hover,
    &:active,
    &:focus,
    &:hover:active,
    &:active:focus,
    &:hover:active:focus {
      background: url($thumbnailUrl);
      background-size: $width $height;
    }
  }

  &__text {
    @include resetSpaces;
    @include generate-theme {
      color: theme-var(button-text-color);
    }
    font-weight: bold;
    position: absolute;
    bottom: 0px;
  }
}
