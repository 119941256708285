@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.PhyzziFrontend {
  @include generate-theme {
    @include setBackgroundColor(theme-var(background-color));
    color: theme-var(text-color);
  }
  height: 100%;
  @include resetSpaces;
  @include resetBorder;
}
