@import "./../../stylesheets/_definitions";

.MapContainer .MapIcon {
  @include setCursor(grab);
}

.MapContainer--clickable-markers .MapIcon {
  @include setPointerCursor;
}

.leaflet-touch .leaflet-bar a {
  width: $mapContainer__leafletIconSize;
  height: $mapContainer__leafletIconSize;
  line-height: $mapContainer__leafletIconSize;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: $text-size;
}

.leaflet-bar a {
  @include generate-theme {
    color: theme-var(map-sizing-button-text-color);
    @include setBackgroundColor(theme-var(map-sizing-button-background-color));
  }

  &:hover {
    @include generate-theme {
      color: theme-var(map-sizing-button-action-text-color);
      @include setBackgroundColor(
        theme-var(map-sizing-button-action-background-color)
      );
    }
  }
}

.leaflet-bar a.leaflet-disabled {
  @include generate-theme {
    color: theme-var(map-sizing-button-background-color);
    @include setBackgroundColor(
      theme-var(map-sizing-button-action-background-color)
    );
  }
}

.leaflet-touch .leaflet-bar {
  @include generate-theme {
    //border: theme-var(button-border);
    //box-shadow: theme-var(map-button-shadow);
  }
  border: 0 none;
}

.leaflet-touch .leaflet-bar a:first-child,
.leaflet-touch .leaflet-bar a:last-child {
  border-radius: $border-radius;
}

/** leaflet zoomslider **/

.Zoomslider {
  position: fixed;
  right: 0px;

  &--partial30 {
    @extend .Zoomslider;
    bottom: 30%;
  }

  &--full {
    @extend .Zoomslider;
    display: none;
  }
}

/** Slider **/
.leaflet-control-zoomslider-wrap {
  //padding-top: 5px;
  //padding-bottom: 5px;
  //background-color: #fff;
  //border-bottom: 1px solid #ccc;
}
.leaflet-control-zoomslider-body {
  @include generate-theme {
    border: solid theme-var(map-sizing-button-background-color);
    box-shadow: theme-var(text-color-lighter) 0px 0px 1px 1px;
  }
  width: 2px;
  border-width: 0px 3px;
  background-color: black;
  margin: 0 auto;
}
.leaflet-control-zoomslider-knob {
  @include generate-theme {
    border: theme-var(button-border);
    @include setBackgroundColor(theme-var(map-sizing-button-text-color));
  }
  position: relative;
  width: 12px;
  height: 4px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-left: -6px;
}
.leaflet-control-zoomslider-body:hover {
  cursor: pointer;
}
.leaflet-control-zoomslider-knob:hover {
  @include generate-theme {
    @include setBackgroundColor(theme-var(map-sizing-button-action-text-color));
  }
  cursor: default;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-dragging .leaflet-control-zoomslider,
.leaflet-dragging .leaflet-control-zoomslider-wrap,
.leaflet-dragging .leaflet-control-zoomslider-body,
.leaflet-dragging .leaflet-control-zoomslider a,
.leaflet-dragging
  .leaflet-control-zoomslider
  a.leaflet-control-zoomslider-disabled,
.leaflet-dragging .leaflet-control-zoomslider-knob:hover {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/** Leaflet Zoom Styles **/
.leaflet-container .leaflet-control-zoomslider {
  margin-left: 10px;
  margin-top: 10px;
}
.leaflet-control-zoomslider a {
  width: 26px;
  height: 26px;
  text-align: center;
  text-decoration: none;
  color: black;
  display: block;
}
.leaflet-control-zoomslider a:hover {
  background-color: #f4f4f4;
}
.leaflet-control-zoomslider-in {
  font: bold 18px "Lucida Console", Monaco, monospace;
}
.leaflet-control-zoomslider-in:after {
  content: "\002B";
}
.leaflet-control-zoomslider-out {
  font: bold 22px "Lucida Console", Monaco, monospace;
}
.leaflet-control-zoomslider-out:after {
  content: "\2212";
}
.leaflet-control-zoomslider a.leaflet-control-zoomslider-disabled {
  cursor: default;
  color: #bbb;
}

/* Touch */
.leaflet-touch .leaflet-control-zoomslider-body {
  background-position: 10px 0px;
}
.leaflet-touch .leaflet-control-zoomslider-knob {
  width: 16px;
  margin-left: -7px;
}
.leaflet-touch .leaflet-control-zoomslider a {
  @include generate-theme {
    border: theme-var(button-border);
  }
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-control-zoomslider a:hover {
  width: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-control-zoomslider-in {
  font-size: 24px;
  line-height: 29px;
}
.leaflet-touch .leaflet-control-zoomslider-out {
  font-size: 28px;
  line-height: 30px;
}
.leaflet-touch .leaflet-control-zoomslider {
  box-shadow: none;
  //border: 4px solid rgba(0,0,0,0.3);
}
