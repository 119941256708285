// This file is to store sizes used in components, which should be shared with other components

// MapContainer
$mapContainer__shadowRadiusSize: 5px;
$mapContainer__iconSize: 36px;
$mapContainer__leafletIconSize: 20px;
$mapContainer__bottomMapIconSetBottomMargin: 205px;
$mapContainer__bottomMapIconSetHorizontalSpace: 40px;
$mapContainer__bottomMapIconSetBottomRightMargin: 10px;
$mapContainer__bottomMapIconSetVerticalSpace: 35px;

// TopSection
$topSection__height: 53px;
$topSection__iconsSize: 36px;
$topSection__userBarIconsSize: $text-size;
$topSection__iconsPadding: 3 * $padding-size;

// VideoModal
$videoModal__topPosition: $topSection__height;

// AlertBox
$alertBox__verticalSpace: 4 * $padding-size;
$alertBox__horizontalSpace: 5 * $padding-size;

// LeftSection
$leftSection_width: 279px;
$leftSection__unitTypeMenuIconFontSize: $text-size;
$leftSection__unitTypeMenuIconPadding: 2 * $padding-size;
$leftSection__unitTypeLevelPadding: $leftSection__unitTypeMenuIconFontSize +
  $leftSection__unitTypeMenuIconPadding;
$leftSection__unitTypeTreeBottomIconsFontSize: 33px;
$leftSection__unitTypeTreeBottomIconsHeight: $leftSection__unitTypeTreeBottomIconsFontSize +
  2 * $padding-size;
$leftSection__sloganHeight: 20px;
$leftSection__footerMenuHeight: 21px;
$leftSection__togglerFontSize: 40px;

// ModalSection
$modalSection__topMargin: 30px;
$modalSection__bottomMargin: 30px;
$modalSection__verticalMargin: 250px;
$modalSection__menuHeight: 30px;
$modalSection__loadingIconFontSize: 200px;
$modalSection__loadingIconMarginTop: 100px;
$modalSection__loadingIconLogoHeight: 60px;
$modalSection__loadingIconHeight: 100px;
$modalSection__loadingIconMarginTop: 70px;
$modalSection__loadingIconHeight--search-box: 10px;
$modalSection__loadingIconMarginTop--search-box: 0px;
$modalSection__infoWidth: 431px;
$modalSection__infoHeight: 400px;
$modalSection__infoUnitTypesWidth: 231px;
$modalSection__infoBoardWidth: 900px;
$modalSection__infoBottomFunctionlButtonFontSize: $text-size--biggest;
$modalSection__infoBottomFunctionalButtonSize: 30px;
$modalSection__infoUserThumbnail: 40px;
$modalSection__infoCellMinWidth: 80px;
$modalSection__infoSideFunctionalButtonSize: 25px;
$modalSection__infoFormHorizontalPadding: 3 * $padding-size;
$modalSection__loginRegisterGlyphSize: 100px;
$modalSection__profileSocialIconSize: 30px;

// Messenger
$messenger__listWidth: 300px;
$messenger__interlocutorBoxHeight: 50px;
$messenger__searchBoxHeight: 30px;
$messenger__messageBoxHeight: 170px;

// CookieInfo
$cookieInfo__bottomMargin: 10%;
$cookieInfo__verticalMargin: 150px;

// AgentButton
$agentButton__bottomMargin: 3%;
$agentButton__verticalMargin: 150px;

// GoogleAdWordsBottom
$googleAdWordsBottom__bottomMargin: 27px;
$googleAdWordsBottom__wideAdWidth: 728px;
$googleAdWordsBottom__wideAdHeight: 90px;
$googleAdWordsBottom__narrowAdWidth: 321px;
$googleAdWordsBottom__narrowAdHeight: 100px;
