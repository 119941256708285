@import "./../../stylesheets/_definitions";

.InfoSectionUnitTypeTreeItem {
  &__name {
    @include resetButtonDecorations;
  }

  &__name-icon {
    @include setMargin(0px, $right: $space-size);
    font-size: 18px;
  }
}
