@import "./../../stylesheets/_definitions";

.UserProfileUnits {
  $expandIconWrapperSize: $text-size--small + getPadding();

  @include resetSpaces;
  list-style: none;

  &__expand-button-wrapper {
    display: inline-block;
    width: $expandIconWrapperSize;
  }

  &__expand-button {
    @include resetButtonDecorations;
  }

  &__expand-icon {
    font-size: $text-size--small;
  }

  &__unit-button {
    @include resetButtonDecorations;
  }

  &__other-posts {
    @include resetButtonDecorations($color-action: button-text-color);
  }

  &__unit-type {
    @include generate-theme {
      color: theme-var(color-light);
    }
    @include setPadding($resetVertical: true);
    font-size: $text-size--small;
    text-transform: capitalize;
  }

  &__remove-button {
    @include resetButtonDecorations($color: button-text-color-negative);
  }
  &__expanded-wrapper {
    @include setPadding(0px, $left: $expandIconWrapperSize);
    font-size: $text-size--small;
  }
  &__boards-wrapper {
    display: table;
    width: 100%;
  }
  &__board-title {
    vertical-align: top;
    display: table-cell;
    width: 50px;
  }
  &__board-cell {
    vertical-align: top;
    @include setPadding(0px, $left: getPadding());
  }
}
