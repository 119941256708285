// This place is to store basic definitions as well as their ancestors.
// Defined variables will be use in theme schemas only. They should not be import anywhere else.

// -------------------------------------------------------------------
// Basic ancestors
// -------------------------------------------------------------------
$eclipse: #3f3939;
$white: #fff;
$mcKenzie: rgb(138, 109, 59);
$contessa: rgb(198, 110, 108);
$fern: rgb(102, 178, 104);
$lochmara: rgb(34, 118, 160);
$christine: #a45c28;
$nobel: #999999;
$broom: #edce26;

$opacity: 0.85;

// -------------------------------------------------------------------
// Basic color palette
// -------------------------------------------------------------------
$mainColor: $white;
$mainOppositeColor: $eclipse;
$positiveColor: $fern;
$negativeColor: $contessa;

$warningColor: lighten(saturate($mcKenzie, 30%), 18%);
$dangerColor: lighten(saturate($contessa, 10%), 5%);
$successColor: lighten(saturate($fern, 0%), 3%);
$infoColor: lighten(saturate($lochmara, 3%), 7%);
$goldColor: $broom;
$silverColor: $nobel;
$bronzeColor: $christine;

$mainColor--light: mix($mainColor, $mainOppositeColor, 75%);
$mainColor--lighter: mix($mainColor, $mainOppositeColor, 50%);
$mainColor--lightest: mix($mainColor, $mainOppositeColor, 33%);
$mainOppositeColor--light: mix($mainOppositeColor, $mainColor, 90%);
$mainOppositeColor--lighter: mix($mainOppositeColor, $mainColor, 80%);
$mainOppositeColor--lightest: mix($mainOppositeColor, $mainColor, 70%);

$mainMapColor: $mainColor;
$mainMapColor--light: darken($mainColor, 13%);
$mainMapColor--lighter: darken($mainColor, 20%);
$mainMapOppositeColor: lighten($mainOppositeColor, 18%);
$mainMapOppositeColor--light: darken($mainMapOppositeColor, 27%);

$positiveColor--light: mix($positiveColor, $mainOppositeColor, 75%);
$negativeColor--light: mix($negativeColor, $mainOppositeColor, 75%);

$goldColor--strong: mix($goldColor, $mainColor, 70%);
$silverColor--strong: mix($silverColor, $mainColor, 70%);
$bronzeColor--strong: mix($bronzeColor, $mainColor, 70%);

// -------------------------------------------------------------------
// Usage definitions (those definitions should be use on theme schema)
// -------------------------------------------------------------------
$textColor: $mainColor;
$textColor--light: $mainColor--light;
$textColor--lighter: $mainColor--lighter;
$textColor--lightest: $mainColor--lightest;
$textColorMap: $mainMapColor;
$textColorMap--light: $mainMapColor--light;
$textColorMap--lighter: $mainMapColor--lighter;
$textColorPositive: $positiveColor;
$textColorPositive--light: $positiveColor--light;
$textColorNegative: $negativeColor;
$textColorNegative--light: $negativeColor--light;
$textColorWarning: $warningColor;
$textColorDanger: $dangerColor;
$textColorSuccess: $successColor;
$textColorInfo: $infoColor;
$textColorGold: $goldColor;
$textColorSilver: $silverColor;
$textColorBronze: $bronzeColor;

$backgroundColor: $mainOppositeColor;
$backgroundColor--light: $mainOppositeColor--light;
$backgroundColor--lighter: $mainOppositeColor--lighter;
$backgroundColor--lightest: $mainOppositeColor--lightest;
$backgroundColor--gold: $goldColor--strong;
$backgroundColor--silver: $silverColor--strong;
$backgroundColor--bronze: $bronzeColor--strong;
$backgroundColorMap: $mainMapOppositeColor;
$backgroundColorMap--light: $mainMapOppositeColor--light;

$borderColor: $mainColor;
$borderColor--light: $mainColor--light;
$borderColor--lighter: $mainColor--lighter;
$borderColor--lightest: $mainColor--lightest;
$borderColor--negative: $negativeColor;

$wikiIcon: "/img/wiki/wiki_satellite.svg";
$wikiIcon--action: "/img/wiki/wiki_satellite_action.svg";
