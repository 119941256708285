@import "./../../stylesheets/_definitions";

.InfoSectionBoardNoResults {
  @include generate-theme {
    color: theme-var(text-color-light);
  }
  @include setPadding(0px, $vertical: getPadding());
  font-size: $text-size--big;
  text-align: center;
}
