@import "./../../stylesheets/_definitions";

.UnitTypeTree {
  @include setMargin(0px, $bottom: 40px);
  @include setPadding($horizontalPadding, $resetVertical: true);
  overflow: auto;
  height: 100%;

  &__sublevel {
    @include setPadding(0px, $left: $leftSection__unitTypeLevelPadding);
  }

  &__bottom-icons-spacer {
    @include setPadding(0px, $top: 2 * getPadding());
    height: $leftSection__unitTypeTreeBottomIconsHeight;
  }
}
