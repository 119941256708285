@import "./../../stylesheets/_definitions";

.UserBar {
  display: inline-block;

  &__user-section {
    @include setMapButton($fontSize: $topSection__userBarIconsSize);
  }
  &__thumbnail-wrapper {
    @include setMargin(0px, $right: 5px, $top: 2px);
    display: inline-block;
  }
  &__thumbnail {
    font-size: $topSection__iconsSize - 4px;
    height: $topSection__iconsSize - 4px;
  }
  &__badge {
    display: inline-block;
  }
  &__menu {
    @include generate-theme {
      border: theme-var(border);
      @include setBackgroundColor(theme-var(background-color));
    }
    @include setBorderRadius;
    @include setPadding();
    position: absolute;
    right: 0px;
    top: $topSection__height;
    min-width: 135px;
    text-align: center;
  }
  &__menu-link {
    @include generate-theme {
      color: theme-var(text-color);
    }
    @include setPointerCursor;
    @include resetBackground;
    @include resetBorder;
    @include resetSpaces;
    &:hover {
      @include generate-theme {
        color: theme-var(action-text-color);
      }
    }
  }
}
