@import "./../../stylesheets/_definitions";

.AgentButton {
  $verticalMargin: $agentButton__verticalMargin;

  @include generate-theme {
    @include setBackgroundOpacity(
      theme-var(opacity),
      theme-var(background-color)
    );
  }
  @include setPadding(0);
  @include getAgentButtonLeftMenuAnimation(expand);
  position: absolute;
  bottom: $agentButton__bottomMargin;
  left: $verticalMargin;
  right: $verticalMargin;

  &--narrow {
    @extend .AgentButton;
    @include getAgentButtonLeftMenuAnimation(narrow);
    left: $verticalMargin + $leftSection_width;
  }

  &__button {
    @include setPadding(2 * getPadding() 4 * getPadding());
    @include setBorderRadius;
    @include setPointerCursor;
    font-weight: bold;
    width: 100%;
    border: 1px solid $borderColor--negative;
    color: $textColorNegative;
    background: transparent;
  }
}
