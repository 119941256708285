@import "./../../stylesheets/_definitions";

.LeftSection {
  @include generate-theme {
    @include setBackgroundOpacity(
      theme-var(opacity),
      theme-var(background-color)
    );
  }
  top: 0px;
  bottom: 0px;
  position: absolute;

  &__sectionToggler {
    top: 50%;
    position: absolute;
    right: -1px;
    font-size: $leftSection__togglerFontSize;
    @include setPointerCursor;
    @include generate-theme {
      @include setTextStroke(
        theme-var(border-size-thin) theme-var(border-color)
      );
      @include setOpacity(theme-var(opacity) * 0.75);
      color: theme-var(background-color);
    }
    @include aboveTwoLevels;
    @include resetBorder;
    @include resetBackground;
    @include resetSpaces;
    width: 31px;

    &:hover,
    &:active,
    &:focus,
    &:hover:active,
    &:active:focus,
    &:hover:active:focus {
      @include resetBackground;
      @include resetShadow($isImportant: true);
      @include generate-theme {
        color: theme-var(background-color-lightest);
      }
    }

    &--hidden {
      @extend .LeftSection__sectionToggler;
      right: -21px;
    }
  }

  .LeftSection__sectionTogglerIcon {
    font-size: 40px;
  }
}
