@import "./../../stylesheets/_definitions";

.ModalSectionMenu {
  @include setPadding($bottom: 0px);
  position: relative;
  height: 30px;
  display: table;
  width: 100%;

  &__nav {
    @include resetMargin;
    @include resetPadding;
    list-style: none;
    display: table-cell;
    text-align: center;

    &--left {
      @extend .ModalSectionMenu__nav;
      text-align: left;
      width: 90px;
    }

    &--right {
      @extend .ModalSectionMenu__nav;
      text-align: right;
      width: 64px;
    }
  }

  &__button {
    @include resetButtonDecorations($padding: getPadding());
  }

  & .container {
    width: 100%;
    @include resetPadding;
  }

  &__item {
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
  }

  &__text-item {
    @include resetButtonDecorations(
      $margin: getSpacing(0px, $horizontal: 2 * getMargin()),
      $border-radius: 0px
    );

    &--active,
    &--active[disabled] {
      @extend .ModalSectionMenu__text-item;
      @include resetCursor;
      @include generate-theme {
        color: theme-var(text-color);
        border-bottom: theme-var(border);
      }
      font-weight: bold;
    }

    &[disabled],
    &[disabled]:hover {
      @include generate-theme {
        color: theme-var(text-color);
      }
      @include resetCursor;
      font-weight: bold;
    }
  }
}
