@import "./../../stylesheets/_definitions";

.InfoSectionUsersAddSection {
  position: relative;
  cursor: pointer;
  @include generate-theme {
    @include setBackgroundColor(theme-var(background-selection-color));
  }

  &--disabled {
    @extend .InfoSectionUsersAddSection;
    cursor: default;
  }

  &__add-yourself {
    @include setMargin($base: 0px, $bottom: getPadding());
    text-align: left;
    font-weight: bold;
  }

  &__add-yourself-info {
    @include resetSpaces;
    text-align: left;
    font-size: $text-size--small;
    font-style: italic;
  }

  &__add-button {
    @include resetButtonDecorations;
    font-size: $modalSection__infoSideFunctionalButtonSize;
    width: $modalSection__infoBottomFunctionalButtonSize;
    height: $modalSection__infoBottomFunctionalButtonSize;
  }
}
