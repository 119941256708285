@import "./../../stylesheets/_definitions";

.InfoSectionBoardRow {
  position: relative;

  &--highlighted {
    @extend .InfoSectionBoardRow;
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-selection-color));
    }
  }

  &__cell {
    @include setPadding(2 * getPadding());

    &--left {
      @extend .InfoSectionBoardRow__cell;
      text-align: left;
    }

    &--group {
      @extend .InfoSectionBoardRow__cell;
      max-width: 150px;
      word-wrap: break-word;
    }

    &--description {
      @extend .InfoSectionBoardRow__cell;
      text-align: justify;
    }

    &--type_buy {
      @extend .InfoSectionBoardRow__cell;
      @include generate-theme {
        @include setTextColor(theme-var(text-color-positive));
      }
      font-weight: bold;
    }

    &--type_sell {
      @extend .InfoSectionBoardRow__cell;
      @include generate-theme {
        @include setTextColor(theme-var(text-color-negative));
      }
      font-weight: bold;
    }
  }

  &__image-wrapper {
    width: $modalSection__infoUserThumbnail;
    height: $modalSection__infoUserThumbnail;
  }

  &__icon {
    font-size: $modalSection__infoUserThumbnail + 1px;
  }

  &__name {
    @include resetButtonDecorations(
      $padding: getSpacing(0px, $right: getPadding()),
      $color: text-color-info,
      $color-action: text-color-info
    );
    font-weight: bold;
    text-align: left;
  }

  &__location {
    font-size: $text-size--small;
    @include generate-theme {
      color: theme-var(text-color-light);
    }
  }

  &__icon-button {
    @include resetButtonDecorations;
    @include setPadding(0px, $right: getPadding());
    font-size: $modalSection__infoSideFunctionalButtonSize;
  }

  &__refresh-button {
    @include resetButtonDecorations;
    @include setMargin(0px, $left: getMargin());
    vertical-align: middle;
  }

  &__refresh-button-icon {
    font-size: 14px;
  }
}
