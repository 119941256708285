@import "./../../stylesheets/_definitions";

.ModalSection__unitInfo {
  @include setModalSectionStyle();
  z-index: 2;
  width: $modalSection__infoWidth;
  height: $modalSection__infoHeight;
  position: fixed;

  &--unitInfo,
  &--unitUsers {
    @extend .ModalSection__unitInfo;
  }

  &--unitTypes {
    @extend .ModalSection__unitInfo;
    @include getWidthAnimation($id: widthAnimationFromUnitTypesToInfo);
  }

  &--unitBoard {
    @extend .ModalSection__unitInfo;
    @include getWidthAnimation($id: widthAnimationFromBoardToInfo);
  }
}

.ModalSectionMenu__nav--unitInfo {
  @include resetSpaces;
  list-style: none;
  display: table-cell;
  text-align: left;
}
