@import "./../../stylesheets/_definitions";

.ModalSection__unitBoard {
  @include setModalSectionStyle();
  z-index: 2;
  width: $modalSection__infoBoardWidth;
  height: $modalSection__infoHeight;
  position: fixed;

  &--unitBoard {
    @extend .ModalSection__unitBoard;
  }

  &--unitInfo,
  &--unitUsers {
    @extend .ModalSection__unitBoard;
    @include getWidthAnimation($id: widthAnimationFromInfoToBoard);
  }
}

.FilterSorterSorter {
  @include generate-theme {
    color: theme-var(text-color-lighter);
  }
  display: flex;

  &--selected {
    @extend .FilterSorterSorter;
    @include generate-theme {
      color: theme-var(text-color-light);
    }
    font-weight: bold;
  }

  &__button {
    @include resetButtonDecorations;
    @include setMargin(0px, $left: getMargin());

    &--selected {
      @extend .FilterSorterSorter__button;
      @include resetButtonDecorations(
        $color: button-action-text-color,
        $color-action: button-action-text-color
      );
      @include resetCursor;
      @include setMargin(0px, $left: getMargin());
    }
  }
}
