@import "./../../stylesheets/_definitions";

.FormData {
  &__input {
  }

  &__addon {
  }

  &__value {
    display: inline-block;

    &--logged {
      @extend .FormData__value;
      @include setPointerCursor;
    }
  }

  &__textarea-buttons-container {
    @include setPadding(0px, $top: getPadding());
    text-align: right;
  }

  &__textarea-counter {
    display: inline-block;
    vertical-align: middle;
    @include setPadding(0px, $right: 2 * getPadding());
    font-size: $text-size--small;
    @include generate-theme {
      color: theme-var(text-color-lighter);
    }
  }

  &__textarea-buttons {
    display: inline-block;
    vertical-align: middle;
  }

  &__save {
    @include setVisibleButton($mode: positive);
    @include generate-theme {
      border: theme-var(border-lightest);
    }
  }

  &__save-icon {
    font-size: 16px;
  }

  &__cancel {
    @include setVisibleButton($mode: negative);
    @include generate-theme {
      border: theme-var(border-lightest);
    }
  }

  &__cancel-icon {
    font-size: 16px;
  }
}
