@import "./../../stylesheets/_definitions";

.SearchResults {
  &__collapse-container {
    position: absolute;
    width: 100%;
  }

  &__wrapper {
    @include generate-theme {
      border: theme-var(border);
      @include setBackgroundColor(theme-var(background-color));
    }
    @include setBorderRadius;
    @include setPadding($horizontal: 3 * getPadding());
    margin: 0 auto;
    width: 100%;
    display: inline-block;
    text-align: left;
    position: relative;
  }

  &__header {
    @include generate-theme {
      color: theme-var(button-text-color);
    }
    text-align: center;
    font-weight: bold;
  }

  &__button {
    @include resetButtonDecorations;
    display: block;
  }
}
