@import "./../../stylesheets/_definitions";

.HowTo {
  $videoWidth: 560px;
  $videoHeight: 315px;

  height: 100%;
  position: relative;
  text-align: center;

  &__wrapper {
    height: 100%;
    text-align: left;
  }

  &__video-wrapper {
    @include generate-theme {
      border: theme-var(border);
    }
    @include setBorderRadius;
    @include resetPadding;
    width: 100%;
    height: 100%;
    min-width: $videoWidth;
    min-height: $videoHeight;
    overflow: hidden;
  }

  &__iframe {
    @include resetBorder;
    width: 100%;
    height: 100%;
  }
}
