@import "./../../stylesheets/_definitions";

.Contact {
  &__email {
    font-size: $text-size;
  }

  &__title {
    font-size: $text-size;
  }

  &__textarea {
    height: 200px !important;
    width: 100% !important;
    font-size: $text-size;
  }

  &__counter {
    @include setCounterStyle;
  }

  &__button-row {
    text-align: right;
    @include setPadding(0px, $top: 2 * getPadding());
  }

  &__button {
    @include setModalButton();
    font-weight: bold;
  }

  &__disclaimer {
    @include generate-theme {
      color: theme-var(text-color-lighter);
      border-top: theme-var(border-lightest);
    }
    @include setMargin(0px, $top: 3 * getMargin());
    @include setPadding(0px, $top: getPadding());
    font-size: $text-size--small;
    text-align: justify;
  }

  &__disclaimer-link {
    @include resetButtonDecorations;
    font-size: $text-size--small;
  }
}
