// This file is to store mixins responsible for setting values.
// Please note there are several complex setters for buttons at  ./_buttons.scss file

//--------------------------------------------
// Property setters
//
// Those mixins returns properties with given values
//--------------------------------------------

/**
 * For setting text-stroke
 */
@mixin setTextStroke($values: null) {
  @if $values {
    text-stroke: #{$values};
    -webkit-text-stroke: #{$values};
  }
}

/**
 * For setting background opacity
 */
@mixin setBackgroundOpacity($level: 1, $bgColor: white) {
  background-color: rgba($bgColor, $level);
}

/**
 * For setting opacity
 */
@mixin setOpacity($opacity: getOpacity()) {
  opacity: $opacity;
}

/**
 * For setting border-radius
 */
@mixin setBorderRadius(
  $size: getBorderRadius(),
  $important: false,
  $reset: false
) {
  @if $reset {
    border-radius: 0px;
  }

  @if $important {
    border-radius: $size !important;
  } @else {
    border-radius: $size;
  }
}

/**
 * For setting margin
 */
@mixin setMargin($keys...) {
  margin: getSpacing($keys...);
}

/**
 * For setting padding
 */
@mixin setPadding($keys...) {
  padding: getSpacing($keys...);
}

/**
 * For setting cursor
 */
@mixin setCursor($cursor: auto) {
  cursor: $cursor;
}

/**
 * For setting pointer cursor
 */
@mixin setPointerCursor() {
  @include setCursor(pointer);
}

/**
 * For setting background color
 */
@mixin setBackgroundColor($backgroundColor: null) {
  background-color: $backgroundColor;
}

/**
 * For setting text color
 */
@mixin setTextColor($color: null) {
  color: $color;
}

//--------------------------------------------
// Mixed setters
//
// Those mixins returns complex properties
//--------------------------------------------

/**
 * For placing elements on the map, which should be above it's tile layer.
 * This z-index does not correspond with application's z-indexes.
 */
@mixin aboveMapTilePane() {
  z-index: 400;
}

/**
 * For placing elements on the map, which should be above it's popup pane.
 * This z-index does not correspond with application's z-indexes.
 */
@mixin aboveMapPopupPane() {
  z-index: 701;
}

/**
 * For placing elements above corresponding elements.
 */
@mixin aboveOneLevel() {
  z-index: 1;
}

/**
 * For placing elements above corresponding elements.
 */
@mixin aboveTwoLevels() {
  z-index: 2;
}

/**
 * For positioning bottom-right icons of a map area (add button and change theme button)
 */
@mixin setBottomMapIconPosition($itemVertical: 0, $itemHorizontal: 0) {
  bottom: $mapContainer__bottomMapIconSetBottomMargin + $itemHorizontal *
    $mapContainer__bottomMapIconSetHorizontalSpace;
  right: $mapContainer__bottomMapIconSetBottomRightMargin + $itemVertical *
    $mapContainer__bottomMapIconSetVerticalSpace;
}

/**
 * For setting common styles for most of ModalSections. It does not cover positioning.
 */
@mixin setModalSectionStyle() {
  @include generate-theme {
    @include setBackgroundOpacity(
      theme-var(opacity),
      theme-var(background-color)
    );
    color: theme-var(text-color);
    border: theme-var(border);
  }
  @include setBorderRadius;
  position: relative;
  overflow: hidden;
}

/**
 * For setting common styles for character counters related to restricted textarea.
 */
@mixin setCounterStyle() {
  text-align: right;
  font-size: $text-size--small;
  @include resetMargin;
  @include generate-theme {
    color: theme-var(text-color-lighter);
  }
}

/**
 * For setting styles for input fields.
 */
@mixin setInputStyle() {
  @include generate-theme {
    @include setBackgroundColor(theme-var(background-color));
    border: theme-var(border-lightest);
    color: theme-var(text-color);
  }
  @include setBorderRadius;
  @include setPadding(
    $vertical: getPadding(),
    $horizontal: $modalSection__infoFormHorizontalPadding
  );
}

/**
 * For setting styles for input decorators (i.e. bootstrap's button group addons)
 */
@mixin setInputDecoratorsStyle() {
  @include generate-theme {
    @include setBackgroundColor(theme-var(background-color));
    border: theme-var(border-lightest);
    color: theme-var(text-color);
  }
}

/**
 * Modification of setInputStyle mixin for input files used on Infobox section
 */
@mixin setInfoInputStyle() {
  @include setInputStyle;
  width: 100%;
  height: 30px;
}
