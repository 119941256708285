// This place is to store basic definitions as well as their ancestors.
// Defined variables will be use in theme schemas only. They should not be import anywhere else.

// -------------------------------------------------------------------
// Basic ancestors
// -------------------------------------------------------------------
$eclipse: #3f3939;
$gray: #737373;
$white: #fff;
$mcKenzie: rgb(138, 109, 59);
$terracotta: rgb(169, 68, 66);
$japaneseLaurel: rgb(60, 118, 61);
$astral: rgb(49, 112, 143);
$richGold: #a45c28;
$nobel: #999999;
$broom: #edce26;

$opacity: 0.85;

// -------------------------------------------------------------------
// Basic color palette
// -------------------------------------------------------------------
$mainColor: lighten($gray, 0%);
$mainOppositeColor: $white;
$positiveColor: $japaneseLaurel;
$negativeColor: $terracotta;

$warningColor: $mcKenzie;
$dangerColor: $terracotta;
$successColor: $japaneseLaurel;
$infoColor: $astral;
$goldColor: $broom;
$silverColor: $nobel;
$bronzeColor: $richGold;

$mainColor--light: mix($mainColor, $mainOppositeColor, 75%);
$mainColor--lighter: mix($mainColor, $mainOppositeColor, 50%);
$mainColor--lightest: mix($mainColor, $mainOppositeColor, 33%);
$mainOppositeColor--light: mix($mainOppositeColor, $mainColor, 90%);
$mainOppositeColor--lighter: mix($mainOppositeColor, $mainColor, 80%);
$mainOppositeColor--lightest: mix($mainOppositeColor, $mainColor, 70%);

$mainMapColor: $mainColor;
$mainMapColor--light: lighten($mainMapColor, 5%);
$mainMapOppositeColor: $mainOppositeColor;
$mainMapOppositeColor--light: darken($mainMapOppositeColor, 13%);

$positiveColor--light: mix($positiveColor, $mainOppositeColor, 75%);
$negativeColor--light: mix($negativeColor, $mainOppositeColor, 75%);

$goldColor--strong: mix($goldColor, $mainColor, 70%);
$silverColor--strong: mix($silverColor, $mainColor, 70%);
$bronzeColor--strong: mix($bronzeColor, $mainColor, 70%);

// -------------------------------------------------------------------
// Usage definitions (those definitions should be use on theme schema)
// -------------------------------------------------------------------
$textColor: $mainColor;
$textColor--light: $mainColor--light;
$textColor--lighter: $mainColor--lighter;
$textColor--lightest: $mainColor--lightest;
$textColorMap: $mainMapColor;
$textColorMap--light: $mainMapColor--light;
$textColorPositive: $positiveColor;
$textColorPositive--light: $positiveColor--light;
$textColorNegative: $negativeColor;
$textColorNegative--light: $negativeColor--light;
$textColorWarning: $warningColor;
$textColorDanger: $dangerColor;
$textColorSuccess: $successColor;
$textColorInfo: $infoColor;
$textColorGold: $goldColor;
$textColorSilver: $silverColor;
$textColorBronze: $bronzeColor;

$backgroundColor: $mainOppositeColor;
$backgroundColor--light: $mainOppositeColor--light;
$backgroundColor--lighter: $mainOppositeColor--lighter;
$backgroundColor--lightest: $mainOppositeColor--lightest;
$backgroundColor--gold: $goldColor--strong;
$backgroundColor--silver: $silverColor--strong;
$backgroundColor--bronze: $bronzeColor--strong;
$backgroundColorMap: $mainMapOppositeColor;
$backgroundColorMap--light: $mainMapOppositeColor--light;

$borderColor: $mainColor;
$borderColor--light: $mainColor--light;
$borderColor--lighter: $mainColor--lighter;
$borderColor--lightest: $mainColor--lightest;
$borderColor--negative: $negativeColor;

$wikiIcon: "/img/wiki/wiki_map.svg";
$wikiIcon--action: "/img/wiki/wiki_map.svg";
