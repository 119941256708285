@import "./../../stylesheets/_definitions";

.MessengerTopic {
  position: absolute;
  bottom: $messenger__messageBoxHeight;
  top: 0px;
  left: $messenger__listWidth + 1;
  right: 0px;
  @include setPadding($resetVertical: true);
}
