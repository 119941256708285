@import "./../../stylesheets/_definitions";

.ReactTagsRemove {
  @include setVisibleButton($mode: negative);
  @include setMargin(0px, $left: getMargin());

  &__icon {
    font-size: 16px;
  }
}
