@import "./../../stylesheets/_definitions";

.UserProfile {
  $titleTableSpace: 5 * getPadding();

  text-align: center;

  &__content {
    text-align: left;
  }

  &__profile-table {
    width: 100%;
  }

  &__title {
    @include setMargin(0px, $bottom: $titleTableSpace);
    text-transform: uppercase;
    font-size: $text-size--biggest;
    font-weight: bold;
  }

  &__account-badge {
    @include setPadding(0px, $left: 2 * getPadding());
    font-size: $text-size;
    text-transform: none;

    &--bronze {
      @extend .UserProfile__account-badge;
      @include generate-theme {
        color: theme-var(text-color-bronze);
        text-shadow-gold: theme-var(text-shadow-bronze);
      }
    }
    &--silver {
      @extend .UserProfile__account-badge;
      @include generate-theme {
        color: theme-var(text-color-silver);
        text-shadow: theme-var(text-shadow-silver);
      }
    }
    &--gold {
      @extend .UserProfile__account-badge;
      @include generate-theme {
        color: theme-var(text-color-gold);
        text-shadow: theme-var(text-shadow-gold);
      }
    }
  }

  &__cell {
    @include setPadding(0px, $right: getPadding(), $bottom: getPadding());
    vertical-align: top;
    font-size: $text-size--big;
    text-align: justify;

    &-wide {
      @extend .UserProfile__cell;
      @include setPadding($vertical: 2 * getPadding(), $resetHorizontal: true);
      width: 100%;
    }

    &-title {
      @extend .UserProfile__cell;
      min-width: 110px;
      max-width: 200px;

      &-wide {
        @extend .UserProfile__cell-title;
        @include setPadding($vertical: 2 * getPadding(), $left: 0px);
      }
    }

    &-picture {
      @extend .UserProfile__cell;
      @include resetPadding;
      width: 200px;
      width: 300px;
    }
  }

  &__link {
    @include generate-theme {
      color: theme-var(text-color);
    }

    &:hover {
      @include generate-theme {
        color: theme-var(action-text-color);
      }
    }
  }

  &__side-wrapper {
    //top: (-1 * $titleTableSpace) - $text-size--biggest;
    //position: relative;
    text-align: right;
  }

  &__side-cell {
    //display: inline-block;
    //display: block;

    vertical-align: bottom;
    @include setMargin(0px, $top: getMargin());

    &--picture-wrapper {
      @extend .UserProfile__side-cell;
      @include setMargin(0px, $bottom: getMargin());
      vertical-align: top;
      height: 100px;
      width: 100px;
      display: inline-block;
    }

    &--picture {
      @extend .UserProfile__side-cell;
      font-size: 101px;
    }

    &--picture-image {
      @include generate-theme {
        border: theme-var(border-lighter);
      }
      @include setBorderRadius;
    }
  }

  &__social-button--linkedin {
    @include setSocialButtonLinkedin($iconOnly: true);
  }

  &__social-button--google {
    @include setSocialButtonGoogle($iconOnly: true);
  }

  &__side-button-wrapper {
    @include setPadding($resetVertical: true, $right: 0px);
    vertical-align: middle;
    display: inline-block;
  }

  &__message-button {
    @include resetButtonDecorations;
    font-size: $modalSection__profileSocialIconSize;
    padding-top: 5px;
    & .material-icons {
      @extend .UserProfile__message-button;
      font-size: 44px;
    }
  }
}
