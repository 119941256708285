$opacity: 0.85;

$border-style: solid;
$border-style-non-solid: dashed;

$linkedinIconUrl: "/img/providers/linkedin.svg";
$googleIconUrl: "/img/providers/google.png";

$socialButtonGoogleBackgroundColor: #fff;
$socialButtonLinkedinBackgroundColor: #0077b5;
$socialButtonLinkedinTextColor: #fff;

$loadingIconAnimationTime: 3s;
$pulsingAnimationTime: 1.5s;

@import "_others_shared_components";
