// This file is to store mixins responsible for theme changing.

/**
  This is helper responsible for getting a value from given map.
 */
@function mget($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

/**
 * This helper is responsible for getting a given property's value. It uses config/_variables.scss
 * For example theme-var(text-color) returns value stored under text-color property of chosen theme.
 */
@function theme-var($variable, $themes: $themes, $theme-name: $theme-name) {
  @return mget($themes, $theme-name, $variable);
}

/**
 * Returns theme variations of given selector. It uses config/_variables.scss If given property exists on theme scheme
 * it add it's value. If given property does not exist, it won't be added to theme variations.
 *
 * Usage example:
 * generate-theme {
 *  color: theme-var(text-color); // Use value stores under text-color property on theme scheme
 *  background-color: theme-var(non-existant-property); // Will not add background-color as property does not exist on theme scheme
 *  border-radius: $border-radius; // Will use variable
 *  padding: getSpacing(); // Will use return value of a function
 *  font-size: 12px; // Will use given value
 * }
 */
@mixin generate-theme($themes: $themes) {
  @each $theme, $map in $themes {
    $theme-name: $theme !global;
    .#{$theme-name} & {
      @content;
    }
  }
}
