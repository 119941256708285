// This file is to store mixins responsible for button presentations.

// Please note, there is a resetButtonDecorations on ./_reseters.scss file

/**
 * Responsible for styling visible buttons (with border and background color). Those buttons are widely use on Infobox.
 * Hover, active and disabled are also covered.
 *
 * Examples of use:
 * setVisibleButton // standard visible button
 * setVisibleButton($mode: positive) // sets standard visible button with positive color of text / icon
 */
@mixin setVisibleButton(
  $mode: null// Color mode flag. Available options: positive,
  negative
) {
  $color: button-text-color;
  $actionColor: button-action-text-color;
  $disabledColor: button-disabled-text-color;

  @if $mode == positive {
    $color: button-text-color-positive;
    $actionColor: button-action-text-color-positive;
    $disabledColor: button-disabled-text-color-positive;
  }

  @if $mode == negative {
    $color: button-text-color-negative;
    $actionColor: button-action-text-color-negative;
    $disabledColor: button-disabled-text-color-negative;
  }

  @include resetShadow;
  @include resetBackground;
  @include generate-theme {
    @include setBackgroundColor(theme-var(background-color));
    color: theme-var($color);
    border: theme-var(button-border);
  }

  &:hover,
  &:active,
  &:focus,
  &:hover:active,
  &:active:focus,
  &:hover:active:focus {
    @include generate-theme {
      @include setBackgroundColor(theme-var(button-background-color));
      color: theme-var($actionColor);
      border: theme-var(visible-button-action-border);
    }
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    @include generate-theme {
      @include setBackgroundColor(theme-var(button-background-color));
      color: theme-var($disabledColor);
      border: theme-var(visible-button-disabled-border);
    }
  }
}

/**
 * This is styling variation of resetButtonDecoration mixin. It creates so called modal buttons.
 * Those are bigger buttons with some padding use on Modal Section for actions like Send a message, Register and so on.
 */
@mixin setModalButton() {
  @include resetButtonDecorations(
    $border: button-border,
    $border-action: button-action-border,
    $border-radius: getBorderRadius(),
    $padding:
      getSpacing($vertical: 2 * getPadding(), $horizontal: 4 * getPadding()),
    $margin: getSpacing(0px, $left: getPadding())
  );
}

/**
 * This is styling variation of resetButtonDecorations for styling social media icon's button.
 *
 * Example of use:
 * setSocialButton($icon: $linkedinIconUrl)
 * setSocialButton($icon: $googleIconUrl)
 */
@mixin setSocialButton($icon: null) {
  @if $icon {
    @include resetButtonDecorations; // width: $socialIconSize;
    height: $socialButtonHeight;
    background: url($icon) no-repeat;
    background-size: $socialIconSize $socialIconSize;
    display: inline-block;
    vertical-align: top;
    &:hover,
    &:active,
    &:focus,
    &:hover:active,
    &:active:focus,
    &:hover:active:focus {
      background: url($icon) no-repeat;
      background-size: $socialIconSize $socialIconSize;
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      background: url($icon) no-repeat;
      background-size: $socialIconSize $socialIconSize;
    }
  }
}
/* *
 * Setting social button with Google requirements */
@mixin setSocialButtonGoogle($iconOnly: null) {
  @include setSocialButton($icon: $googleIconUrl);
  @include generate-theme {
    border: theme-var(button-border);
  }
  @if $iconOnly != true {
    @include setPadding(
      $left: $socialButtonGoogleLeftPadding + $socialIconSize +
        $socialButtonGoogleMiddlePadding,
      $right: $socialButtonGoogleRightPadding
    );
  }
  @if $iconOnly {
    width: $socialButtonGoogleLeftPadding +
      $socialIconSize +
      $socialButtonGoogleRightPadding;
  }
  @include setBackgroundColor($socialButtonGoogleBackgroundColor);
  background-position: $socialButtonGoogleLeftPadding
    ($socialButtonHeight - $socialIconSize) / 2;
  font-size: $socialButtonGoogleFontSize;
  &:hover,
  &:active,
  &:focus,
  &:hover:active,
  &:active:focus,
  &:hover:active:focus {
    @include generate-theme {
      border: theme-var(visible-button-action-border);
    }
    @include setBackgroundColor($socialButtonGoogleBackgroundColor);
    background-position: $socialButtonGoogleLeftPadding
      ($socialButtonHeight - $socialIconSize) / 2;
  }
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    @include generate-theme {
      border: theme-var(visible-button-disabled-border);
    }
    @include setBackgroundColor($socialButtonGoogleBackgroundColor);
    background-position: $socialButtonGoogleLeftPadding
      ($socialButtonHeight - $socialIconSize) / 2;
  }
}
/* *
 * Setting social button with Linkedin requirements */
@mixin setSocialButtonLinkedin($iconOnly: null) {
  @include setSocialButton($icon: $linkedinIconUrl);
  @include generate-theme {
    border: theme-var(button-border);
  }
  @if $iconOnly != true {
    @include setPadding(
      $left: $socialButtonLinkedinIconSize + $socialButtonLinkedinMiddlePadding,
      $right: $socialButtonLinkedinRightPadding
    );
  }
  @if $iconOnly {
    width: $socialButtonLinkedinIconSize;
  }
  @include setBackgroundColor($socialButtonLinkedinBackgroundColor);
  background-position: 0px 0px;
  font-size: $socialButtonLinkedinFontSize;
  background-size: $socialButtonLinkedinIconSize $socialButtonLinkedinIconSize;
  @include generate-theme {
    color: $socialButtonLinkedinTextColor;
  }
  &:hover,
  &:active,
  &:focus,
  &:hover:active,
  &:active:focus,
  &:hover:active:focus {
    @include setBackgroundColor($socialButtonLinkedinBackgroundColor);
    @include generate-theme {
      border: theme-var(visible-button-action-border);
      color: $socialButtonLinkedinTextColor;
    }
    background-position: 0px 0px;
    background-size: $socialButtonLinkedinIconSize $socialButtonLinkedinIconSize;
  }
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    @include setBackgroundColor($socialButtonLinkedinBackgroundColor);
    @include generate-theme {
      border: theme-var(visible-button-disabled-border);
      color: $socialButtonLinkedinTextColor;
    }
    background-position: 0px 0px;
    background-size: $socialButtonLinkedinIconSize $socialButtonLinkedinIconSize;
  }
}
/* *
 * This is styling variation of resetButtonDecorations mixin for styling buttons, which appear directly above the map.
 *
 * Examples of use:
 * setMapButton()
 * setMapButton($fontSize: 12px) */
@mixin setMapButton($fontSize: $mapContainer__iconSize) {
  @include resetButtonDecorations(
    $color: map-button-text-color,
    $color-action: map-button-action-text-color,
    $text-shadow: map-button-shadow,
    $text-shadow-action: map-button-action-shadow
  );
  font-size: $fontSize;
}
