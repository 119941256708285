@import "./../../stylesheets/_definitions";

.Messenger {
  width: 100%;
  height: 100%;
  position: relative;

  &__right-content {
    display: inline-block;
  }
}
