@import "./../../stylesheets/_definitions";

.Slogan {
  @include generate-theme {
    color: theme-var(text-color-lighter);
  }
  text-align: left;
  vertical-align: bottom;
  height: $leftSection__sloganHeight;
  bottom: 0px;
  position: absolute;
  width: $leftSection_width;
  font-size: $text-size--smaller;
  @include setPadding($resetVertical: true, $horizontal: $horizontalPadding);

  &__copyright {
    @include generate-theme {
      color: theme-var(text-color-light);
    }
    font-weight: bold;
  }
}
