@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.ModalSection {
  @include setModalSectionStyle;
  @include resetPadding;
  @include aboveOneLevel;
  margin: 0 auto;
  min-width: 600px;
  max-width: 900px;
  height: 80%;

  &__extender {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}
