@import "./../../stylesheets/_definitions";

.ModalSectionExternalContent {
  @include resetBorder;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: scroll;

  &--youtube {
    @extend .ModalSectionExternalContent;
    min-width: 560px;
    min-height: 315px;
  }
}
