@import "./../../stylesheets/_definitions";

.InfoSectionInfoMaintenance {
  &__edit {
    @include setPointerCursor;
  }

  .DateInput_input {
    @include setPointerCursor;
  }

  .DateRangePickerInput {
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-color));
    }
  }

  .DateRangePickerInput__withBorder {
    @include generate-theme {
      border: theme-var(border-lightest);
    }
  }

  .DateInput_input {
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-color));
      color: theme-var(text-color);
    }
  }
}
