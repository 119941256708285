@import "./../../stylesheets/_definitions";

.MessengerMessage {
  $thumbnailSize: 20px;

  text-align: left;
  @include setPadding($vertical: getPadding(), $horizontal: 2 * getPadding());

  &--author {
    @extend .MessengerMessage;
    text-align: right;
  }

  &__content {
    width: 80%;
    display: inline-block;
  }

  &__text {
    @include generate-theme {
      border: theme-var(border-lighter);
    }
    @include setBorderRadius;
    @include setPadding();
    display: inline-block;
    width: 85%;
    vertical-align: top;
    text-align: left;
  }

  &__thumbnail-wrapper {
    @include setMargin(0px, $right: getMargin());
    display: inline-block;
    vertical-align: top;
    width: $thumbnailSize;
    height: $thumbnailSize;

    &--author {
      @extend .MessengerMessage__thumbnail-wrapper;
      @include setMargin(0px, $left: getMargin());
    }
  }

  &__thumbnail {
    font-size: $thumbnailSize + 1px;
  }

  &__date {
    @include generate-theme {
      color: theme-var(text-color);
    }
    font-size: $text-size--smaller;
    @include setPadding(
      $horizontal: $thumbnailSize + getPadding(),
      $bottom: 0px
    );
  }
}
