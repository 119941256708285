@import "./../../stylesheets/_definitions";

.App {
  @include resetSpaces;
  @include resetBorder;
  width: 100%;
  height: 80%;
  position: fixed;
}

@media print {
  * {
    @include generate-theme {
      &,
      &::before,
      &::after {
        color: theme-var(text-color-print) !important;
      }
    }
  }

  a[href]::after {
    content: "";
  }
}

::-webkit-scrollbar {
  width: 1em;

  &-thumb {
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-color-lightest));
    }
  }

  &-corner {
    @include setBackgroundColor(transparent);
  }
}
