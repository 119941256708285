@import "./../../stylesheets/_definitions";

.InfoSectionBoardNewMessage {
  text-align: left;
  @include generate-theme {
    border-top: theme-var(border-lighter);
  }
  @include setPadding(0px, $top: getPadding());

  &__row {
    @include setPadding($vertical: 2 * getPadding(), $horizontal: 0px);

    &--right {
      text-align: right;
    }
  }

  &__select {
    @include setMargin(0px, $right: getMargin());
  }

  &__textarea {
    @include resetMargin;
  }

  &__counter {
    @include setCounterStyle;
  }

  &__button {
    @include setModalButton();
  }
}
