@import "./../../stylesheets/_definitions";

.AddButton {
  position: absolute;
  right: 10px;
  bottom: 0px;
  @include aboveMapTilePane;

  &--partial30 {
    @extend .AddButton;
    bottom: 30%;
  }

  &--full {
    @extend .AddButton;
    display: none;
  }

  &__button {
    @include setMapButton;
    @include setBottomMapIconPosition(0, 0);
    position: relative;
    right: 0px;
  }

  &__button-icon {
    font-size: 36px;
  }
}
