@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.InfoSectionBoard {
  height: 100%;
  position: relative;
  text-align: center;

  &__wrapper {
    height: 100%;
  }

  &__table {
    display: block;
    width: 100%;
    @include setPadding(0px, $bottom: getPadding());
  }
}
