.PhyzziBrandLoader {
  text-align: center;
  height: 100px;
  margin-top: 100px;

  &__image-wrapper {
    animation: fullRotation 3s linear infinite;
  }

  &__image {
    height: 60px;
  }
}

@keyframes fullRotation {
  0% {
    transform: rotate(0turn);
  }
  100% {
    transform: rotate(1turn);
  }
}
