@import "./../../stylesheets/_definitions";

.ModalGroup {
  top: $modalSection__topMargin;
  bottom: $modalSection__bottomMargin;
  left: $leftSection_width;
  right: 0px;
  position: absolute;
  @include getModalLeftMenuAnimation($mode: narrow);

  &--hidden_menu {
    @extend .ModalGroup;
    left: 0px;
    @include getModalLeftMenuAnimation($mode: expand);
  }
}
