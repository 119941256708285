@import "./../../stylesheets/_definitions";

.ShareButton {
  display: inline-block;
  @include setMargin(0px, $right: $topSection__iconsPadding);

  &__button {
    @include setMapButton($fontSize: $topSection__iconsSize);
    vertical-align: sub;
  }
  &__button-icon {
    font-size: 36px;
  }
  &__share-menu-wrapper {
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-color));
      @include setOpacity(theme-var(opacity));
      border: theme-var(border);
    }
    @include setBorderRadius;
    @include setPadding();
    position: absolute;
    right: 45px;
    top: $topSection__height;
    text-align: center;
  }
  &__share-menu {
    display: table;
  }
  &__share-menu-provider {
    display: table-cell;
    @include setPadding($vertical: 0px);
    vertical-align: top;
    @include generate-theme {
      color: theme-var(text-color);
    }
  }
}
