@import "./../../stylesheets/_definitions";

.MapAttribution {
  @include aboveTwoLevels;
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.7);
  margin: 0px;
  padding: 0px 5px;
  font-size: 11px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}
