@import "./../../stylesheets/_definitions";

.Faq {
  .panel-default > .panel-heading {
    @include resetBackground;
    @include resetBorder;
    @include resetPadding;
  }

  .panel-default > .panel-heading + .panel-collapse > .panel-body {
    @include resetBorder;
  }

  .panel-group .panel {
    @include resetBackground;
    @include resetBorder;
    @include resetShadow;
  }
}
