$text-size: 14px;
$text-size--small: 12px;
$text-size--smaller: 10px;
$text-size--big: 16px;
$text-size--bigger: 18px;
$text-size--biggest: 20px;

$space-size: 4px;
$space-size--small: 2px;
$padding-size: $space-size;
$padding-size--small: $space-size--small;
$margin-size: $space-size;
$margin-size--small: $space-size--small;

$horizontalPadding: 3 * $padding-size;

$border-radius: $space-size;
$border-size: 2px;
$border-size--thin: 1px;

$socialIconSize: 18px;
$socialButtonHeight: 31px;

$socialButtonGoogleLeftPadding: 8px;
$socialButtonGoogleMiddlePadding: 16px;
$socialButtonGoogleRightPadding: 8px;
$socialButtonGoogleFontSize: 14px;

$socialButtonLinkedinIconSize: $socialButtonHeight;
$socialButtonLinkedinMiddlePadding: 16px;
$socialButtonLinkedinRightPadding: 8px;
$socialButtonLinkedinFontSize: 14px;

$pulsingIconCircleInitialSize: 10px;
$pulsingIconCircleFinalSize: 30px;
$pulsingIconCircleFinalTop: -10px;
$pulsingIconCircleFinalLeft: -10px;

$pulsingIconTriangleInitialSize: 14px;
$pulsingIconTriangleFinalSize: 42px;
$pulsingIconTriangleFinalTop: -18px;
$pulsingIconTriangleFinalLeft: -14px;

$pulsingIconSquareInitialSize: 10px;
$pulsingIconSquareFinalSize: 30px;
$pulsingIconSquareFinalTop: -10px;
$pulsingIconSquareFinalLeft: -10px;

$pulsingIconDiamondInitialSize: 14px;
$pulsingIconDiamondFinalSize: 42px;
$pulsingIconDiamondFinalTop: -14px;
$pulsingIconDiamondFinalLeft: -14px;

@import "_sizes_shared_components";
