@import "./../../stylesheets/_definitions";

.LeftSectionStructure {
  width: $leftSection_width;
  height: 100%;
  @include generate-theme {
    border-right: theme-var(border);
  }

  &--collapsed {
    @extend .LeftSectionStructure;
    &.width.collapsing {
      @include getLeftMenuAnimation($mode: collapse);
    }
  }

  &--expanded {
    @extend .LeftSectionStructure;
    &.width.collapsing {
      @include getLeftMenuAnimation($mode: expand);
    }
  }
}
