@import "./../../stylesheets/_definitions";

.ModalSection__loginRegister {
  @include setModalSectionStyle();
  @include aboveOneLevel;
  margin: 0 auto;
  min-width: 600px;
  max-width: 900px;
  height: 80%;
}

.Register {
  &__intro {
    font-size: $text-size--big;
    @include setPadding(0px, $bottom: 4 * getPadding());
  }

  &__confirmation-glyph {
    text-align: center;
    @include setPadding(0px, $top: 40px);

    &--success {
      @extend .Register__confirmation-glyph;
      @include generate-theme {
        color: theme-var(text-color-positive);
      }

      .material-icons {
        font-size: $modalSection__loginRegisterGlyphSize;
      }
    }

    &--fail {
      @extend .Register__confirmation-glyph;
      @include generate-theme {
        color: theme-var(text-color-negative);
      }

      .material-icons {
        font-size: $modalSection__loginRegisterGlyphSize;
      }
    }
  }

  &__content-right {
    text-align: right;
  }

  &__input {
    @include setInputStyle;

    &--invalid {
      @extend .Register__input;
      @include generate-theme {
        border-color: theme-var(border-color-negative);
      }
    }
  }

  &__checkbox {
    position: relative;
    @include setMargin($left: 0px, $right: 2 * getMargin());
  }

  &__checkbox-label {
    &--nonMandatory {
      @extend .Register__checkbox;
      font-style: italic;

      .Register__textLink {
        font-style: italic;
      }
    }
  }

  &__checkbox-label-asterisk {
    @include setMargin($left: getMargin());
    @include generate-theme {
      color: theme-var(text-color-negative);
    }
  }

  &__textLink {
    @include resetButtonDecorations;
  }

  &__button {
    @include setVisibleButton;
    @include setMargin(0px, $right: 2 * getMargin());
    @include setPadding($horizontal: 2 * getPadding());
  }

  &__providers {
    @include setPadding(0px, $vertical: 2 * getPadding());
  }

  &__social {
    @include setMargin(0px, $top: 2 * getMargin());

    &--linkedin {
      @extend .Register__social;
      @include setSocialButtonLinkedin();
    }
    &--google {
      @extend .Register__social;
      @include setSocialButtonGoogle();
    }
  }

  &__disclaimer {
    @include generate-theme {
      color: theme-var(text-color-lighter);
      border-top: theme-var(border-lightest);
    }
    @include setMargin(0px, $bottom: 3 * getMargin());
    @include setPadding(0px, $top: getPadding());
    font-size: $text-size--small;
    text-align: justify;
  }
}

.Login {
  &__intro {
    font-size: $text-size--big;
    @include setPadding(0px, $bottom: 4 * getPadding());
  }

  &__confirmation-glyph {
    font-size: $modalSection__loginRegisterGlyphSize;
    text-align: center;
    @include setPadding(0px, $top: 40px);

    &--success {
      @extend .Login__confirmation-glyph;
      @include generate-theme {
        color: theme-var(text-color-positive);
      }

      .material-icons {
        font-size: $modalSection__loginRegisterGlyphSize;
      }
    }

    &--fail {
      @extend .Login__confirmation-glyph;
      @include generate-theme {
        color: theme-var(text-color-negative);
      }

      .material-icons {
        font-size: $modalSection__loginRegisterGlyphSize;
      }
    }
  }

  &__input {
    @include setInputStyle;
  }

  &__content-right {
    text-align: right;
  }

  &__button {
    @include setVisibleButton;
    @include setMargin(0px, $top: 2 * getMargin());
    @include setPadding($horizontal: 2 * getPadding());
  }

  &__providers {
    @include setPadding(0px, $vertical: 2 * getPadding());
  }

  &__social {
    @include setMargin(0px, $top: 2 * getMargin());

    &--linkedin {
      @extend .Login__social;
      @include setSocialButtonLinkedin();
    }
    &--google {
      @extend .Login__social;
      @include setSocialButtonGoogle();
    }
  }

  &__disclaimer {
    @include generate-theme {
      color: theme-var(text-color-lighter);
      border-top: theme-var(border-lightest);
    }
    @include setMargin(0px, $bottom: 3 * getMargin());
    @include setPadding(0px, $top: getPadding());
    font-size: $text-size--small;
    text-align: justify;
  }

  &__forgot-password-button {
    @include resetButtonDecorations;
    font-size: $text-size--small;
  }
}

.ResetPassword {
  &__intro {
    font-size: $text-size--big;
    @include setPadding(0px, $bottom: 15px);
  }

  &__confirmation-glyph {
    font-size: 100px;
    text-align: center;
    @include setPadding(0px, $top: 40px);

    &--success {
      @extend .ResetPassword__confirmation-glyph;
      @include generate-theme {
        color: theme-var(text-color-positive);
      }

      .material-icons {
        font-size: $modalSection__loginRegisterGlyphSize;
      }
    }

    &--fail {
      @extend .ResetPassword__confirmation-glyph;
      @include generate-theme {
        color: theme-var(text-color-negative);
      }

      .material-icons {
        font-size: $modalSection__loginRegisterGlyphSize;
      }
    }
  }

  &__button {
    @include setVisibleButton;
    @include setMargin(0px, $right: 2 * getMargin());
    @include setPadding($horizontal: 2 * getPadding());
  }

  &__providers {
    @include setPadding(0px, $vertical: 4 * getPadding());
  }

  &__disclaimer {
    @include generate-theme {
      color: theme-var(text-color-lighter);
      border-top: theme-var(border-lightest);
    }
    @include setMargin(0px, $top: 3 * getMargin());
    @include setPadding(0px, $top: getPadding());
    font-size: $text-size--small;
    text-align: justify;
  }
}

.ResetPasswordRequest {
  &__intro {
    font-size: $text-size--big;
    @include setPadding(0px, $bottom: 15px);
  }

  &__confirmation-glyph {
    font-size: $modalSection__loginRegisterGlyphSize;
    text-align: center;
    @include setPadding(0px, $top: 40px);

    &--success {
      @extend .ResetPasswordRequest__confirmation-glyph;
      @include generate-theme {
        color: theme-var(text-color-positive);
      }

      .material-icons {
        font-size: $modalSection__loginRegisterGlyphSize;
      }
    }

    &--fail {
      @extend .ResetPasswordRequest__confirmation-glyph;
      @include generate-theme {
        color: theme-var(text-color-negative);
      }

      .material-icons {
        font-size: $modalSection__loginRegisterGlyphSize;
      }
    }
  }

  &__button {
    @include setVisibleButton;
    @include setMargin(0px, $right: 2 * getMargin());
    @include setPadding($horizontal: 2 * getPadding());
  }

  &__providers {
    @include setPadding(0px, $vertical: 4 * getPadding());
  }

  &__disclaimer {
    @include generate-theme {
      color: theme-var(text-color-lighter);
      border-top: theme-var(border-lightest);
    }
    @include setMargin(0px, $top: 3 * getMargin());
    @include setPadding(0px, $top: getPadding());
    font-size: $text-size--small;
    text-align: justify;
  }
}
