@import "./../../stylesheets/_definitions";

.SearchIcon {
  @include resetButtonDecorations;
  @include generate-theme {
    color: theme-var(text-color);
    @include setOpacity(0.6);
  }
  font-size: 20px;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;

  &--menu {
    @extend .SearchIcon;
    top: 4px;
  }
}

.SearchIcon .PhyzziBrandLoader {
  height: $modalSection__loadingIconHeight--search-box;
  margin-top: $modalSection__loadingIconMarginTop--search-box;
}

.SearchIcon .PhyzziBrandLoader__image-wrapper {
  width: 20px;
  height: 20px;
}

.SearchIcon .PhyzziBrandLoader__image {
  height: $modalSection__loadingIconHeight--search-box;
  vertical-align: super;
}
