@import "./../../stylesheets/_definitions";

.EditPositionMarker {
  &__popup {
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      @include generate-theme {
        @include setBackgroundOpacity(
          theme-var(opacity),
          theme-var(background-color)
        );
      }
    }

    .leaflet-popup-content-wrapper {
      @include setBorderRadius;
    }

    .leaflet-popup-content {
      width: 2 * $modalSection__infoBottomFunctionlButtonFontSize + 2 * 17px !important;
    }
  }

  &__button {
    @include resetButtonDecorations(
      $padding: getPadding(),
      $margin: getMargin()
    );
    font-size: $modalSection__infoBottomFunctionlButtonFontSize;
  }
}
