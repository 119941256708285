@import "./../../stylesheets/_definitions";

.Captcha {
  &__resolved {
    height: 1px;
  }

  &__footer {
    position: relative;
  }
}
