@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.InfoSectionInfo {
  &__table {
    width: 100%;
  }

  &__button {
    @include setVisibleButton();
  }

  &__button--positive {
    @include setVisibleButton($mode: positive);
  }

  &__button--negative {
    @include setVisibleButton($mode: negative);
  }

  &__button-icon {
    font-size: 21px;
    vertical-align: top;
  }
}
