@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.InfoSectionUnitTypes {
  &__buttons {
    position: absolute;
    bottom: 2 * $space-size;
    right: 7 * $space-size;
  }

  &__button {
    @include resetButtonDecorations(
      $padding: getPadding(),
      $margin: getMargin()
    );
    font-size: $modalSection__infoBottomFunctionlButtonFontSize;
  }
}
