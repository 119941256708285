@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.Faq {
  height: 100%;
  position: relative;
  text-align: center;

  &__wrapper {
    height: 100%;
    text-align: left;
  }
}
