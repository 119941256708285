@import "./../../stylesheets/_definitions";

.InfoSectionInfoOwnership {
  &__list {
    @include setPadding($reset: true);
    @include resetMargin;
    list-style: none;
  }

  &__edit-list {
    @include resetCursor;
    display: inline;

    &--active {
      @extend .InfoSectionInfoOwnership__edit-list;
      @include setPointerCursor;
    }
  }

  &__form {
    display: inline-block;
    vertical-align: bottom;

    &--name {
      @extend .InfoSectionInfoOwnership__form;
      @include setMargin(0px, $right: getMargin());
      width: 100px;
    }

    &--ownership {
      @extend .InfoSectionInfoOwnership__form;
      @include setMargin($vertical: 0px, $horizontal: getMargin());
      width: 164px;
    }

    &--new-ownership {
      @extend .InfoSectionInfoOwnership__form;
      @include setMargin($vertical: 0px, $horizontal: getMargin());
      width: 98px;
    }
  }
}
