@import "./../../stylesheets/_definitions";

.LanguageButton {
  $mainIconHeight: 23px;
  $menuIconHeight: 20px;

  $flagUrl--en: "/img/flags/en.svg";
  $flagUrl--ru: "/img/flags/ru.svg";
  $flagUrl--cn: "/img/flags/cn.svg";

  display: inline-block;
  @include setMargin(0px, $right: $topSection__iconsPadding);

  &__button {
    @include setMapButton($fontSize: $topSection__iconsSize);
    @include setMargin(0px, $top: -3px);
    height: $mainIconHeight;
    vertical-align: middle;

    &--en {
      @extend .LanguageButton__button;
      @include setOpacity;
      background: url($flagUrl--en) no-repeat;
      width: 1.33 * $mainIconHeight;
      background-size: 1.33 * $mainIconHeight $mainIconHeight;

      &:hover,
      &:active,
      &:focus,
      &:hover:active,
      &:active:focus,
      &:hover:active:focus {
        @include resetOpacity;
        background: url($flagUrl--en) no-repeat;
        width: 1.33 * $mainIconHeight;
        background-size: 1.33 * $mainIconHeight $mainIconHeight;
      }
    }

    &--ru {
      @extend .LanguageButton__button;
      @include setOpacity;
      background: url($flagUrl--ru) no-repeat;
      width: 1.33 * $mainIconHeight;
      background-size: 1.33 * $mainIconHeight $mainIconHeight;

      &:hover,
      &:active,
      &:focus,
      &:hover:active,
      &:active:focus,
      &:hover:active:focus {
        @include resetOpacity;
        background: url($flagUrl--ru) no-repeat;
        width: 1.33 * $mainIconHeight;
        background-size: 1.33 * $mainIconHeight $mainIconHeight;
      }
    }
  }

  &__language-menu-wrapper {
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-color));
      @include setOpacity(theme-var(opacity));
      border: theme-var(border);
    }
    @include setBorderRadius;
    @include setPadding($double: true);
    position: absolute;
    right: 100px;
    top: $topSection__height;
    text-align: center;
  }

  &__language-menu {
    @include resetButtonDecorations;
    @include setPadding(0px, $left: 30px, $vertical: getPadding());
    position: relative;
    text-align: left;
    display: block;

    &--en {
      @extend .LanguageButton__language-menu;
      background: url($flagUrl--en) no-repeat 0px getPadding();
      background-size: auto $menuIconHeight;

      &:hover,
      &:active,
      &:focus,
      &:hover:active,
      &:active:focus,
      &:hover:active:focus {
        background: url($flagUrl--en) no-repeat 0px getPadding();
        background-size: auto $menuIconHeight;
      }
    }

    &--ru {
      @extend .LanguageButton__language-menu;
      background: url($flagUrl--ru) no-repeat 0px getPadding();
      background-size: auto $menuIconHeight;

      &:hover,
      &:active,
      &:focus,
      &:hover:active,
      &:active:focus,
      &:hover:active:focus {
        background: url($flagUrl--ru) no-repeat 0px getPadding();
        background-size: auto $menuIconHeight;
      }
    }

    &--cn {
      @extend .LanguageButton__language-menu;
      background: url($flagUrl--cn) no-repeat 0px getPadding();
      background-size: auto $menuIconHeight;

      &:hover,
      &:active,
      &:focus,
      &:hover:active,
      &:active:focus,
      &:hover:active:focus {
        background: url($flagUrl--cn) no-repeat 0px getPadding();
        background-size: auto $menuIconHeight;
      }
    }
  }

  &__coming-soon {
    font-size: $text-size--smaller;
  }
}
