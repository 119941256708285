@import "./../../stylesheets/_definitions";

.MessengerInterlocutor {
  height: $messenger__interlocutorBoxHeight;

  &__name {
    @include resetButtonDecorations;
    font-weight: bold;
    font-size: $text-size--big;
  }

  &__position {
    @include generate-theme {
      color: theme-var(text-color-light);
    }
  }
}
