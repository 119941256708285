@import "./../../stylesheets/_definitions";

$providerIconOpacity: 0.5;

.UserSettings {
  text-align: center;

  &__section {
    text-align: left;
    @include setPadding($top: 10px, $bottom: 20px, $resetHorizontal: true);
  }

  &__section-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: $text-size--big;
    @include setPadding($vertical: 2 * getPadding(), $resetHorizontal: true);
  }

  &__section-table {
    display: table;
    width: 100%;
    @include setPadding(0px, $top: 2 * getPadding());
  }

  &__section-cell {
    display: table-cell;
    width: 50%;

    &--right {
      @extend .UserSettings__section-cell;
      text-align: right;
    }
  }

  &__provider-button--linkedin {
    @include setSocialButtonLinkedin();
    @include setMargin(0px, $right: getMargin(), $bottom: getMargin());

    &:hover,
    &:focus,
    &:active {
      @extend .UserSettings__provider-button--linkedin;
      @include generate-theme {
        @include setOpacity($providerIconOpacity);
      }
    }

    &-not-connected {
      @extend .UserSettings__provider-button--linkedin;
      @include generate-theme {
        @include setOpacity($providerIconOpacity);
      }

      &:hover,
      &:focus,
      &:active {
        @extend .UserSettings__provider-button--linkedin-not-connected;
        @include generate-theme {
          @include setOpacity(1);
        }
      }
    }
  }

  &__provider-button--google {
    @include setSocialButtonGoogle();
    @include setMargin(0px, $right: getMargin(), $bottom: getMargin());

    &:hover,
    &:focus,
    &:active {
      @extend .UserSettings__provider-button--google;
      @include generate-theme {
        @include setOpacity($providerIconOpacity);
      }
    }

    &-not-connected {
      @extend .UserSettings__provider-button--google;
      @include generate-theme {
        @include setOpacity($providerIconOpacity);
      }

      &:hover,
      &:focus,
      &:active {
        @extend .UserSettings__provider-button--google-not-connected;
        @include generate-theme {
          @include setOpacity(1);
        }
      }
    }
  }

  &__section-description-wrapper {
    @include generate-theme {
      color: theme-var(text-color-light);
      border: theme-var(border-non-solid);
      @include setBackgroundColor(theme-var(background-selection-color));
    }
    @include setPadding();
    font-size: $text-size--small;
    @include setBorderRadius;
    @include setMargin(0px, $top: 5 * getMargin());
    text-align: justify;
  }

  &__button {
    @include resetButtonDecorations;
  }

  &__account-types {
    width: 100%;
    max-width: 800px;
    text-align: center;
  }

  &__thead {
    text-transform: uppercase;
    text-align: center;

    &--left {
      @extend .UserSettings__thead;
      text-align: left;
    }

    &--right {
      @extend .UserSettings__thead;
      text-align: right;
    }
  }

  &__account-type-selected-glyph {
    @include setPadding(0px, $right: 2 * getPadding());
    @include generate-theme {
      color: theme-var(button-text-color-positive);
    }
  }

  &__account-type-glyph--positive {
    @include generate-theme {
      color: theme-var(button-text-color-positive);
    }
  }

  &__account-type-glyph--negative {
    @include generate-theme {
      color: theme-var(button-text-color-negative);
    }
  }

  &__account-type-name {
    text-align: left;
    @include setPadding($resetHorizontal: true);
    text-transform: capitalize;

    &--bronze {
      @extend .UserSettings__account-type-name;
      @include generate-theme {
        color: theme-var(text-color-bronze);
        text-shadow-gold: theme-var(text-shadow-bronze);
      }
    }

    &--silver {
      @extend .UserSettings__account-type-name;
      @include generate-theme {
        color: theme-var(text-color-silver);
        text-shadow-gold: theme-var(text-shadow-silver);
      }
    }

    &--gold {
      @extend .UserSettings__account-type-name;
      @include generate-theme {
        color: theme-var(text-color-gold);
        text-shadow-gold: theme-var(text-shadow-gold);
      }
    }
  }

  &__account-type--right {
    text-align: right;
  }
}
