@import "./../../stylesheets/_definitions";

.InfoSectionBoardFirstRow {
  position: relative;
  @include generate-theme {
    @include setBackgroundColor(theme-var(background-selection-color));
  }

  &__add-yourself {
    @include setMargin($base: 0px, $bottom: getPadding());
    text-align: left;
    font-weight: bold;
  }

  &__add-yourself-info {
    @include resetSpaces;
    text-align: left;
    font-size: $text-size--small;
    font-style: italic;
  }

  &__icon-button {
    @include resetButtonDecorations;
    @include setPadding(0px, $right: getPadding());
    font-size: $modalSection__infoSideFunctionalButtonSize;
  }
}
