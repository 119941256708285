// This file is to store mixins responsible for animations.

//--------------------------------------------
// Animations
//--------------------------------------------

/**
 * For loading-data animation. It's use while loading some data to show them on component (i.e. modal window)
 */
@mixin getLoadingAnimation() {
  animation: fullRotation $loadingIconAnimationTime linear infinite;
}

/**
 * For pulsing animation. It's use on icon of selected unit.
 */
@mixin getPulsingAnimation($animationType: "pulsing-circle") {
  animation: $animationType $pulsingAnimationTime linear infinite;
}

/**
 * For change-width animation. It's use while changing InfoBox content. For example Boards section is wider than Users.
 */
@mixin getWidthAnimation($id: null) {
  @if $id {
    animation: $id $modalSection__infoWidthAnimationTime ease 1;
  }
}

/**
 * For collapsing/expanding left menu
 */
@mixin getLeftMenuAnimation($mode: null) {
  $animationStyle: 0.3s ease 1;
  @if $mode {
    @if $mode == collapse {
      animation: collapseLeftMenuAnimation $animationStyle;
    }

    @if $mode == expand {
      animation: expandLeftMenuAnimation $animationStyle;
    }
  }
}

/**
 * For following left menu collapse/expand by modal window
 */
@mixin getBottomSectionLeftMenuAnimation($mode: null) {
  $animationStyle: 0.3s ease 1;
  @if $mode {
    @if $mode == narrow {
      animation: narrowBottomSectionAnimation $animationStyle;
    }

    @if $mode == extend {
      animation: extendBottomSectionAnimation $animationStyle;
    }
  }
}

/**
 * For following left menu collapse/expand by modal window
 */
@mixin getModalLeftMenuAnimation($mode: null) {
  $animationStyle: 0.3s ease 1;
  @if $mode {
    @if $mode == narrow {
      animation: narrowModalAnimation $animationStyle;
    }

    @if $mode == expand {
      animation: expandModalAnimation $animationStyle;
    }
  }
}

/**
 * For following left menu collapse/expand by CookieInfo window
 */
@mixin getCookieInfoLeftMenuAnimation($mode: null) {
  $animationStyle: 0.3s ease 1;
  @if $mode {
    @if $mode == narrow {
      animation: narrowCookieInfoAnimation $animationStyle;
    }

    @if $mode == expand {
      animation: expandCookieInfoAnimation $animationStyle;
    }
  }
}

/**
 * For following left menu collapse/expand by AgentButton window
 */
@mixin getAgentButtonLeftMenuAnimation($mode: null) {
  $animationStyle: 0.3s ease 1;
  @if $mode {
    @if $mode == narrow {
      animation: narrowAgentButtonAnimation $animationStyle;
    }

    @if $mode == expand {
      animation: expandAgentButtonAnimation $animationStyle;
    }
  }
}

//--------------------------------------------
// Keyframes
//--------------------------------------------

/**
 * For full rotation animation (i.e. with loading-data animation)
 */
@mixin getFullRotationKeyframes() {
  0% {
    transform: rotate(0turn);
  }
  100% {
    transform: rotate(1turn);
  }
}

/**
 * For pulsing animation (pulsing icon of clicked unit)
 */
@mixin getPulsingKeyframes(
  $initialSize: 10px,
  $finalSize: 30px,
  $finalLeft: -10px,
  $finalTop: -10px
) {
  0% {
    width: $initialSize;
    height: $initialSize;
    opacity: 0.7;
    left: 0px;
    top: 0px;
  }
  100% {
    width: $finalSize;
    height: $finalSize;
    opacity: 0.01;
    left: $finalLeft;
    top: $finalTop;
  }
}
/* *
 * For width change animation (i.e. collapsing left menu, or info box widering) */
@mixin getWidthAnimationKeyframes($initialWidth: 100px, $finalWidth: 200px) {
  0% {
    width: $initialWidth;
  }
  100% {
    width: $finalWidth;
  }
}
/* *
 * For left change animation (i.e. left menu is collapsing so ModalGroup wrapper has to start from 0px of left) */
@mixin getLeftAnimationKeyframes($initialLeft: 100px, $finalLeft: 200px) {
  0% {
    left: $initialLeft;
  }
  100% {
    left: $finalLeft;
  }
}
/* *
 * For changing enlightenment of logo when left-side menu is collapsing */
@mixin getLightenLogoAnimationKeyframes(
  $initialShadow: none,
  $finalShadow: none,
  $initialBackground: 0 none,
  $finalBackground: 0 none
) {
  0% {
    background: $initialBackground;
    box-shadow: $initialShadow;
  }
  100% {
    background: $finalBackground;
    box-shadow: $finalShadow;
  }
}
