@import "./../../stylesheets/_definitions";

.PrivacyPolicy {
  $chapterPaddingTop: 30px;
  height: 100%;
  position: relative;
  text-align: center;

  &__wrapper {
    height: 100%;
    text-align: left;
  }

  &__chapter-block {
    @include setMargin(0px, $bottom: 2 * getPadding());
  }

  &__chapter {
    @include setPadding(0px, $bottom: 2 * getPadding());
    @include resetMargin;
    text-align: left;
    font-weight: bold;
    font-size: $text-size;
  }

  &__content-block {
    @include setMargin(0px, $bottom: 4 * getPadding());
  }

  &__content {
    @include setPadding(0px, $bottom: 2 * getPadding());
    @include resetMargin;
    text-align: justify;
    font-size: $text-size;
  }
}
