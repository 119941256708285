@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.ModalSectionContent {
  overflow: auto;
  top: $modalSection__menuHeight + getPadding();
  bottom: getPadding();
  left: 0px;
  right: 0px;
  position: absolute;
  @include setPadding($horizontal: 40px, $vertical: 30px);

  &--unitInfo,
  &--unitTypes,
  &--unitUsers,
  &--unitBoard {
    @extend .ModalSectionContent;
    @include setPadding($horizontal: 10px, $vertical: 10px + getPadding());
  }

  &__content_container {
    position: relative;
    height: 100%;
    @include generate-theme {
      border: theme-var(border-lighter);
    }
  }
}
