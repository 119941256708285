@import "./../../stylesheets/_definitions";

.FaqContent {
  $chapterPaddingTop: 30px;

  @include setPadding(0px, $top: $chapterPaddingTop);

  &__empty {
    @include resetPadding;
    font-weight: bold;
  }

  &__chapter-card {
    @include resetBorder;
    @include resetBackground;
  }

  &__chapter-wrapper {
    @include resetBorder;
    @include resetSpaces;
    @include resetBackground;
  }

  &__chapter {
    @include setPadding(0px, $bottom: getPadding());
    @include generate-theme {
      color: theme-var(text-color);
    }
    font-weight: bold;
    font-size: $text-size--bigger;
  }

  &__chapter-button {
    @include resetButtonDecorations;
  }

  &__title {
    @include setPadding(0px, $bottom: getPadding());
    @include resetMargin;
    text-align: left;
  }

  &__content-group {
    @include setPadding($resetHorizontal: true);
    @include generate-theme {
      color: theme-var(text-color);
    }
  }

  &__content {
    @include setPadding(0px, $bottom: getPadding());
    @include resetMargin;
    text-align: justify;
  }
}
