@import "./../../stylesheets/_definitions";

.MessengerListTopics {
  @include setMargin(0px, $top: 4 * getMargin());
  position: absolute;
  bottom: 0px;
  top: $messenger__interlocutorBoxHeight;
  width: $messenger__listWidth - 1;
  @include setPadding(0px, $horizontal: 2 * getPadding());
  overflow: auto;
}
