@import "./../../stylesheets/_definitions";

.MessengerNewMessage {
  height: $messenger__messageBoxHeight;
  position: absolute;
  bottom: 0px;
  left: $messenger__listWidth + 1;
  right: 0px;
  @include setPadding($top: 4 * getPadding());

  textarea.form-control {
    height: 100px;
  }

  &__counter {
    @include setCounterStyle;
    @include setPadding($resetHorizontal: true);
  }

  &__button-wrapper {
    text-align: right;
  }

  &__button {
    @include setModalButton;
  }
}
