@import "./../../stylesheets/_definitions";

.InfoSectionInfoTag {
  &__tags {
    @include resetButtonDecorations(
      $color: text-color,
      $color-disabled: text-color
    );
    @include setMargin(0px, $right: getMargin());
    display: inline;
    vertical-align: baseline;
    white-space: normal;
    text-align: left;
    &[disabled] {
      @include resetOpacity;
      @include setCursor($cursor: text);
    }
  }
}
