// This file is to store mixins responsible for size changing.

/**
 * Returns value for padding or margin property.
 * Examples of use:
 * getSpacing() // Will return actual $padding-size value
 * getSpacing(5px) // 5px
 * getSpacing($reset: true) // 0px
 * getSpacing($top: 4px) // Will return 4px at spacing top and actual $padding-size value at others.
 *    May be 4px (if $padding-size = 4px), as well as 4px 6px 6px (if $padding-size = 6px)
 * getSpacing(0px, $vertical: 2px) // 2px 0px
 */
@function getSpacing(
  $sizes: null,
  // basic sizes to start from (i.e. 0px / 10px 2px / 1px 2px 3px / 1px 2px 3px 4px)
    $base: null,
  // base size of padding (if null, $padding-size will be use)
    $double: false,
  // will double base size of padding
    $triple: false,
  // will triple base size of padding
    $top: null,
  // top padding
    $right: null,
  // right padding
    $bottom: null,
  // bottom padding
    $left: null,
  // left padding
    $horizontal: null,
  // horizontal padding
    $vertical: null,
  // vertical padding
    $reset: false,
  // set padding to 0px
    $resetHorizontal: false,
  // set horizontal padding to 0px
    $resetVertical: false// set vertical padding to 0px
) {
  @if $reset == true {
    @return 0px;
  }

  @if $base == null {
    $base: getPadding();
  }

  @if $double {
    $base: $base * 2;
  }

  @if $triple {
    $base: $base * 3;
  }

  $paddingTop: $base;
  $paddingRight: $base;
  $paddingBottom: $base;
  $paddingLeft: $base;

  @if $sizes {
    @if length($sizes) == 1 {
      $paddingTop: nth($sizes, 1);
      $paddingRight: nth($sizes, 1);
      $paddingBottom: nth($sizes, 1);
      $paddingLeft: nth($sizes, 1);
    }

    @if length($sizes) == 2 {
      $paddingTop: nth($sizes, 1);
      $paddingRight: nth($sizes, 2);
      $paddingBottom: nth($sizes, 1);
      $paddingLeft: nth($sizes, 2);
    }

    @if length($sizes) == 3 {
      $paddingTop: nth($sizes, 1);
      $paddingRight: nth($sizes, 2);
      $paddingBottom: nth($sizes, 3);
      $paddingLeft: nth($sizes, 2);
    }

    @if length($sizes) == 4 {
      $paddingTop: nth($sizes, 1);
      $paddingRight: nth($sizes, 2);
      $paddingBottom: nth($sizes, 3);
      $paddingLeft: nth($sizes, 4);
    }
  }

  @if $horizontal {
    $paddingLeft: $horizontal;
    $paddingRight: $horizontal;
  }

  @if $vertical {
    $paddingTop: $vertical;
    $paddingBottom: $vertical;
  }

  @if $top {
    $paddingTop: $top;
  }
  @if $right {
    $paddingRight: $right;
  }
  @if $bottom {
    $paddingBottom: $bottom;
  }
  @if $left {
    $paddingLeft: $left;
  }

  @if $resetHorizontal {
    $paddingLeft: 0px;
    $paddingRight: 0px;
  }

  @if $resetVertical {
    $paddingTop: 0px;
    $paddingBottom: 0px;
  }

  @if (
    $paddingTop ==
      $paddingRight and
      $paddingRight ==
      $paddingBottom and
      $paddingBottom ==
      $paddingLeft
  ) {
    @return $paddingTop;
  }
  @if (
    $paddingTop ==
      $paddingBottom and
      $paddingLeft ==
      $paddingRight and
      $paddingTop !=
      $paddingLeft
  ) {
    @return $paddingTop $paddingLeft;
  }
  @if (
    $paddingTop !=
      $paddingBottom and
      $paddingLeft ==
      $paddingRight and
      $paddingTop !=
      $paddingLeft
  ) {
    @return $paddingTop $paddingLeft $paddingBottom;
  }
  @return $paddingTop $paddingRight $paddingBottom $paddingLeft;
}
