@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.UnitTypeMenu {
  @include generate-theme {
    color: theme-var(color-light);
  }
  width: 100%;
  height: 20px;
  overflow: hidden;

  &__section {
    display: table-cell;
    vertical-align: top;

    &--name {
      @extend .UnitTypeMenu__section;
      width: 100%;
      word-break: break-all;
    }
  }

  &__filter-button {
    @include resetButtonDecorations;
    @include setMargin(0px, $left: getMargin());
    @include setPadding($resetVertical: true);
    font-size: $text-size--small;
  }

  &__filter-glyph {
    @include setMargin(0px, $left: getMargin());
  }
}
