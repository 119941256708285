@import "./../../stylesheets/_definitions";

.InfoSectionInfoData {
  &__table-header {
    font-weight: bold;
  }

  &__table-left {
    @include setPadding(0px, $right: getPadding());
    width: 100px;
    vertical-align: top;
  }

  &__table-right {
    @include resetPadding;
    vertical-align: top;
  }

  &__capacity-unit {
    @include setPadding(0px, $left: getPadding());
  }
}
