@import "./../../stylesheets/_definitions";

.FooterMenu {
  text-align: left;
  vertical-align: bottom;
  height: $leftSection__footerMenuHeight;
  bottom: $leftSection__sloganHeight;
  position: absolute;
  width: $leftSection_width;
  font-size: $text-size--smaller;
  overflow: hidden;
  word-break: break-all;
  @include setPadding($horizontal: $horizontalPadding);

  &__heading {
    @include resetSpaces;
    font-size: $text-size--smaller;
    display: inline-block;
  }

  &__menu-link {
    @include generate-theme {
      color: theme-var(text-color);
    }
    @include setPointerCursor;
    @include setPadding($horizontal: getPadding("small"), $resetVertical: true);
    @include resetBorder;
    @include resetBackground;

    &:hover {
      @include generate-theme {
        color: theme-var(action-text-color);
      }
    }
  }
}
