@import "./../../stylesheets/_definitions";
@import "./additionalStyles.scss";

.MapContainer {
  @include setMargin(0 auto);
  height: 100%;
  width: 100%;

  &__positionContainer {
    @include aboveMapPopupPane;
  }

  &--clickable-markers {
    @extend .MapContainer;
  }
}
