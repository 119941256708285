@import "./../../stylesheets/_definitions";

.LeftSectionContent {
  @include resetPadding;
  overflow: auto;
  top: $topSection__height;
  bottom: $leftSection__sloganHeight + $leftSection__footerMenuHeight;
  position: absolute;
  width: $leftSection_width - $border-size--thin;
}
