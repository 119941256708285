@import "./../../stylesheets/_definitions";

.InfoSectionInfoButtons {
  font-size: $modalSection__infoBottomFunctionlButtonFontSize;
  @include resetButtonDecorations($padding: getPadding(), $margin: getMargin());

  &__right {
    text-align: right;
  }
}
