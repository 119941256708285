@import "./../../stylesheets/_definitions";

.Logo {
  @include resetMargin;
  @include generate-theme {
    color: theme-var(text-color);
  }
  text-align: left;
  font-size: 31px;
  text-transform: none;
  text-decoration: none;
  font-size: $text-size;

  &__link,
  &__link:hover {
    display: inline-block;
  }
}
