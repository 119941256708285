@import "./../../stylesheets/_definitions";

.InfoSectionInfo {
  .FormData {
    @include resetMargin;
  }

  .ReactTags__tagInput {
    position: relative;
  }

  .ReactTags__tagInputField {
    @include setInfoInputStyle;
  }

  .ReactTags__selected .ReactTags__tag {
    @include setPadding(getPadding($mode: small));
  }

  .ReactTags__suggestions {
    @include setBorderRadius;
    position: absolute;
    @include generate-theme {
      border: theme-var(border-lighter);
      @include setBackgroundColor(theme-var(background-color));
    }
    z-index: 3;
    //max-height: 122px;
    overflow: hidden;

    ul {
      @include resetSpaces;
    }

    li {
      list-style: none;
      @include setPadding(
        $vertical: getPadding(),
        $horizontal: $modalSection__infoFormHorizontalPadding
      );
      @include setPointerCursor;
    }

    mark {
      @include resetPadding;
      @include generate-theme {
        @include setBackgroundColor(theme-var(background-color-lightest));
      }
      font-weight: bold;
    }
  }
  .ReactTags__activeSuggestion {
    @include generate-theme {
      @include setBackgroundColor(theme-var(background-color-lightest));
    }
    list-style: none;
    @include setPadding(
      $vertical: getPadding(),
      $horizontal: $modalSection__infoFormHorizontalPadding
    );
    @include setBorderRadius;

    mark {
      @include resetPadding;
      @include generate-theme {
        @include setBackgroundColor(theme-var(background-color-lightest));
      }
      font-weight: bold;
    }
  }

  .has-error {
    .FormData__input {
      @include generate-theme {
        border: theme-var(button-border-negative);
      }
    }
  }
}
