@import "./../../stylesheets/_definitions";

.MessengerMessages {
  overflow: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  top: $messenger__interlocutorBoxHeight;
  bottom: 0px;

  &__loading-trigger {
    text-align: center;
    @include setPadding(0px, $horizontal: getPadding());
    @include generate-theme {
      color: theme-var(text-color-lighter);
    }
    font-size: $text-size--small;
  }
}
