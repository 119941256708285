@import "./../themes/map-theme/_schema";
@import "./../themes/satellite-theme/_schema";

@import "./../partials/_sizes";
@import "./../partials/_others";

$themes: (
  map-theme: (
    // Text
      text-color: $mapTheme__text-color,
    text-color-light: $mapTheme__text-color--light,
    text-color-lighter: $mapTheme__text-color--lighter,
    text-color-warning: $mapTheme__text-color--warning,
    text-color-danger: $mapTheme__text-color--danger,
    text-color-success: $mapTheme__text-color--success,
    text-color-info: $mapTheme__text-color--info,
    text-color-gold: $mapTheme__text-color--gold,
    text-color-silver: $mapTheme__text-color--silver,
    text-color-bronze: $mapTheme__text-color--bronze,
    text-color-positive: $mapTheme__text-color--positive,
    text-color-negative: $mapTheme__text-color--negative,
    action-text-color: $mapTheme__text-color--light,
    text-shadow-gold: 0px 0px 0.5px $mapTheme__background-color--gold,
    text-shadow-silver: 0px 0px 0.5px $mapTheme__background-color--silver,
    text-shadow-bronze: 0px 0px 0.5px $mapTheme__background-color--bronze,
    text-color-print: black,
    // Background
      background-color: $mapTheme__background-color,
    background-color-light: $mapTheme__background-color--light,
    background-color-lightest: $mapTheme__background-color--lightest,
    background-color-gold: $mapTheme__background-color--gold,
    background-color-silver: $mapTheme__background-color--silver,
    background-color-bronze: $mapTheme__background-color--bronze,
    background-action-color: $mapTheme__background-color--lightest,
    background-selection-color: $mapTheme__background-color--light,
    // Border
      border: $mapTheme__border-color $border-size--thin $border-style,
    border-light: $mapTheme__border-color--light $border-size--thin
      $border-style,
    border-lighter: $mapTheme__border-color--lighter $border-size--thin
      $border-style,
    border-lightest: $mapTheme__border-color--lightest $border-size--thin
      $border-style,
    border-non-solid: $mapTheme__border-color $border-size--thin
      $border-style-non-solid,
    border-non-solid-light: $mapTheme__border-color--light $border-size--thin
      $border-style-non-solid,
    border-color: $mapTheme__border-color,
    border-color-light: $mapTheme__border-color--light,
    border-color-lightest: $mapTheme__border-color--lightest,
    border-color-negative: $mapTheme__border-color--negative,
    border-size-thin: $border-size--thin,
    // Button
      button-background-color: $mapTheme__background-color,
    button-background-color-lightest: $mapTheme__background-color--lightest,
    button-text-color: $mapTheme__text-color--light,
    button-text-color-positive: $mapTheme__text-color--positive,
    button-text-color-negative: $mapTheme__text-color--negative,
    button-border: $mapTheme__border-color--lighter $border-size--thin
      $border-style,
    button-border-negative: $mapTheme__border-color--negative $border-size--thin
      $border-style,
    button-action-text-color: $mapTheme__text-color,
    button-action-text-color-positive: $mapTheme__text-color--positive,
    button-action-text-color-negative: $mapTheme__text-color--negative,
    button-action-border: $mapTheme__border-color $border-size--thin
      $border-style,
    button-disabled-text-color: $mapTheme__text-color--lightest,
    button-disabled-text-color-positive: $mapTheme__text-color--positive-light,
    button-disabled-text-color-negative: $mapTheme__text-color--negative-light,
    button-disabled-border: 0 none,
    visible-button-action-border: $mapTheme__border-color $border-size--thin
      $border-style,
    visible-button-disabled-border: $mapTheme__border-color--lightest
      $border-size--thin $border-style,
    // Map
      map-button-text-color: $mapTheme__map-text-color--light,
    map-button-action-text-color: $mapTheme__text-color,
    map-button-shadow: 0px 0px $mapContainer__shadowRadiusSize
      $mapTheme__map-background-color--light,
    map-button-action-shadow: 0px 0px $mapContainer__shadowRadiusSize
      $mapTheme__map-background-color,
    map-button-shadow-color: $mapTheme__map-background-color,
    map-sizing-button-text-color: $mapTheme__map-background-color--light,
    map-sizing-button-action-text-color: $mapTheme__map-background-color,
    map-sizing-button-background-color: $mapTheme__map-text-color--light,
    map-sizing-button-action-background-color: $mapTheme__text-color,
    // Tooltip
      tooltip-background-color: $mapTheme__background-color--lightest,
    tooltip-text-color: $mapTheme__text-color,
    tooltip-border-color: $mapTheme__background-color--lightest,
    // Others
      opacity: $mapTheme__opacity,
    phyzzi-brand-text-stroke-color: $mapTheme__text-color,
    wiki-icon: $mapTheme__wiki-icon,
    wiki-icon-action: $mapTheme__wiki-icon-action,
    logo-lighten-animation: lightenLogoAnimationMap,
    logo-darken-animation: darkenLogoAnimationMap
  )
  /*satellite-theme: (
  // Right now no plans for satellite view
    // Text
      text-color: $satelliteTheme__text-color,
    text-color-light: $satelliteTheme__text-color--light,
    text-color-lighter: $satelliteTheme__text-color--lighter,
    text-color-warning: $satelliteTheme__text-color--warning,
    text-color-danger: $satelliteTheme__text-color--danger,
    text-color-success: $satelliteTheme__text-color--success,
    text-color-info: $satelliteTheme__text-color--info,
    text-color-gold: $satelliteTheme__text-color--gold,
    text-color-silver: $satelliteTheme__text-color--silver,
    text-color-bronze: $satelliteTheme__text-color--bronze,
    text-color-positive: $satelliteTheme__text-color--positive,
    text-color-negative: $satelliteTheme__text-color--negative,
    action-text-color: $mapTheme__text-color--light,
    text-shadow-gold: 0px 0px 0.5px $satelliteTheme__background-color--gold,
    text-shadow-silver: 0px 0px 0.5px $satelliteTheme__background-color--silver,
    text-shadow-bronze: 0px 0px 0.5px $satelliteTheme__background-color--bronze,
    text-color-print: yellow,
    // Background
      background-color: $satelliteTheme__background-color,
    background-color-light: $satelliteTheme__background-color--light,
    background-color-lightest: $satelliteTheme__background-color--lightest,
    background-color-gold: $satelliteTheme__background-color--gold,
    background-color-silver: $satelliteTheme__background-color--silver,
    background-color-bronze: $satelliteTheme__background-color--bronze,
    background-action-color: $satelliteTheme__background-color--lightest,
    background-selection-color: $satelliteTheme__background-color--light,
    // Border
      border: $satelliteTheme__border-color $border-size--thin $border-style,
    border-light: $satelliteTheme__border-color--light $border-size--thin
      $border-style,
    border-lighter: $satelliteTheme__border-color--lighter $border-size--thin
      $border-style,
    border-lightest: $satelliteTheme__border-color--lightest $border-size--thin
      $border-style,
    border-non-solid: $satelliteTheme__border-color $border-size--thin
      $border-style-non-solid,
    border-non-solid-light: $satelliteTheme__border-color--light
      $border-size--thin $border-style-non-solid,
    border-color: $satelliteTheme__border-color,
    border-color-light: $satelliteTheme__border-color--light,
    border-color-lightest: $satelliteTheme__border-color--lightest,
    border-color-negative: $satelliteTheme__border-color--negative,
    border-size-thin: $border-size--thin,
    // Button
      button-background-color: $satelliteTheme__background-color,
    button-background-color-lightest:
      $satelliteTheme__background-color--lightest,
    button-text-color: $satelliteTheme__text-color--light,
    button-text-color-positive: $satelliteTheme__text-color--positive,
    button-text-color-negative: $satelliteTheme__text-color--negative,
    button-border: $satelliteTheme__border-color--lighter $border-size--thin
      $border-style,
    button-border-negative: $satelliteTheme__border-color--negative
      $border-size--thin $border-style,
    button-action-text-color: $satelliteTheme__text-color,
    button-action-text-color-positive: $satelliteTheme__text-color--positive,
    button-action-text-color-negative: $satelliteTheme__text-color--negative,
    button-action-border: $satelliteTheme__border-color $border-size--thin
      $border-style,
    button-disabled-text-color: $satelliteTheme__text-color--lightest,
    button-disabled-text-color-positive:
      $satelliteTheme__text-color--positive-light,
    button-disabled-text-color-negative:
      $satelliteTheme__text-color--negative-light,
    button-disabled-border: 0 none,
    visible-button-action-border: $satelliteTheme__border-color
      $border-size--thin $border-style,
    visible-button-disabled-border: $satelliteTheme__border-color--lightest
      $border-size--thin $border-style,
    // Map
      map-button-text-color: $satelliteTheme__map-text-color,
    map-button-action-text-color: $satelliteTheme__map-text-color--light,
    map-button-shadow: 0px 0px $mapContainer__shadowRadiusSize
      $satelliteTheme__map-background-color,
    map-button-action-shadow: 0px 0px $mapContainer__shadowRadiusSize
      $satelliteTheme__map-background-color--light,
    map-button-shadow-color: $satelliteTheme__map-background-color,
    map-sizing-button-text-color: $satelliteTheme__map-background-color--light,
    map-sizing-button-action-text-color: $satelliteTheme__map-background-color,
    map-sizing-button-background-color: $satelliteTheme__map-text-color--light,
    map-sizing-button-action-background-color: $satelliteTheme__text-color,
    // Tooltip
      tooltip-background-color: $satelliteTheme__background-color--lightest,
    tooltip-text-color: $satelliteTheme__text-color,
    tooltip-border-color: $satelliteTheme__background-color--lightest,
    // Others
      opacity: $satelliteTheme__opacity,
    phyzzi-brand-text-stroke-color: $satelliteTheme__background-color,
    wiki-icon: $satelliteTheme__wiki-icon,
    wiki-icon-action: $satelliteTheme__wiki-icon-action,
    logo-lighten-animation: lightenLogoAnimationSatellite,
    logo-darken-animation: darkenLogoAnimationSatellite
  )*/
);
