@import "./../../stylesheets/_definitions";

.ModalSection__unitTypes {
  @include setModalSectionStyle();
  @include aboveOneLevel;
  position: fixed;
  width: $modalSection__infoUnitTypesWidth;
  height: $modalSection__infoHeight;

  &--unitInfo {
    @extend .ModalSection__unitTypes;
    @include getWidthAnimation($id: widthAnimationFromInfoToUnitTypes);
  }
}

.ModalSectionMenu__nav--unitTypes {
  @include resetSpaces;
  list-style: none;
  display: table-cell;
  text-align: left;
}
