@import "./../../stylesheets/_definitions";

.PhyzziBrandLogo {
  $imageHeight: $topSection__iconsSize - 4px;

  &__image {
    @include generate-theme {
      background: 0 none;
      box-shadow: none;
      animation: theme-var(logo-lighten-animation);
    }
    height: $imageHeight;
    vertical-align: baseline;

    &--enlightened {
      @extend .PhyzziBrandLogo__image;
      @include generate-theme {
        background: rgba(theme-var(map-button-shadow-color), 0.32);
        box-shadow: theme-var(map-button-shadow);
        animation: theme-var(logo-darken-animation);
      }
    }
  }
}
