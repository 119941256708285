@import "./../../stylesheets/_definitions";

.InfoSectionUnitTypeTree {
  .InfoSectionUnitTypeTreeItem__name {
    font-weight: bold;
  }

  &__sublevel {
    padding-left: $leftSection__unitTypeLevelPadding;

    .InfoSectionUnitTypeTreeItem__name {
      font-weight: normal;
    }
  }
}
