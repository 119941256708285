@import "./../../stylesheets/_definitions";

.MapSection {
  @include generate-theme {
    @include setBackgroundColor(theme-var(background-color));
  }
  position: fixed;
  width: 100%;
  height: 100%;
}
