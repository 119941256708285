@import "./../../stylesheets/_definitions";

.UnitTypeTreeButtons {
  position: sticky;
  bottom: 0px;
  right: 0px;
  text-align: right;
  height: $leftSection__unitTypeTreeBottomIconsHeight;
  padding-right: 20px;

  &__uncheck {
    @include resetButtonDecorations;
    font-size: $leftSection__unitTypeTreeBottomIconsFontSize;
  }
}
