@import "./../../stylesheets/_definitions";

.MessengerList {
  width: $messenger__listWidth;
  display: inline-block;
  height: 100%;
  @include generate-theme {
    border-right: theme-var(border-lightest);
  }
}
