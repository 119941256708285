// This file is to store mixins responsible for reseting values.

/**
 * For reset borders
 */
@mixin resetBorder($isImportant: null) {
  @if $isImportant {
    border: 0 none !important;
    @include setBorderRadius(0, $important: true);
  } @else {
    border: 0 none;
    @include setBorderRadius($reset: true);
  }
}

/**
 * For reset backgrounds
 */
@mixin resetBackground() {
  background: 0 none;
}

/**
 * For reset opacity
 */
@mixin resetOpacity($isImportant: null) {
  @if $isImportant {
    @include setOpacity(1 !important);
  } @else {
    @include setOpacity(1);
  }
}

/**
 * For reset shadow (box-shadow and text-shadow)
 */
@mixin resetShadow($isImportant: null) {
  @if $isImportant {
    box-shadow: none !important;
    text-shadow: none !important;
  } @else {
    box-shadow: none;
    text-shadow: none;
  }
}

/**
 * For reset margins
 */
@mixin resetMargin() {
  @include setMargin($reset: true);
}

/**
 * For reset paddings
 */
@mixin resetPadding() {
  @include setPadding($reset: true);
}

/**
 * For reset margins and paddings together
 */
@mixin resetSpaces() {
  @include resetMargin;
  @include resetPadding;
}

/**
 * For reset cursors
 */
@mixin resetCursor() {
  @include setCursor;
}

/**
 * For complex reset of bootstrap buttons (hover, focus and disable are also covered).
 * It's possible to switch default style to the theme-scheme definition, or directly point out the value.
 *
 * Use examples:
 * resetButtonDecorations($border-radius: 10px) // Will add border-radius
 * resetButtonDecorations($padding: getSpacing()) // Will add padding of a value returned by getSpacing() function
 * resetButtonDecorations($color: text-color, $color-action: text-color-positive) // Will add text-color theme scheme value
 *  to the color property as well as text-color-positive theme scheme value to hover, focus and other actions.
 */
@mixin resetButtonDecorations(
  $border-radius: null,
  // definition to use
    $padding: null,
  // definition to use
    $margin: null,
  // definition to use
    $color: button-text-color,
  // name of theme variable to use
    $color-action: button-action-text-color,
  // name of theme variable to use
    $color-disabled: button-disabled-text-color,
  // name of theme variable to use
    $background: null,
  // name of theme variable to use
    $background-action: null,
  // name of theme variable to use
    $background-disabled: null,
  // name of theme variable to use
    $border: null,
  // name of theme variable to use
    $border-action: null,
  // name of theme variable to use
    $border-disabled: null,
  // name of theme variable to use
    $text-shadow: null,
  // name of theme variable to use
    $text-shadow-action: null,
  // name of theme variable to use
    $text-shadow-disabled: null// name of theme variable to use
) {
  @include resetBackground;
  @include resetBorder;
  @include resetShadow;
  @include resetSpaces;
  @include generate-theme {
    @include setBorderRadius($border-radius);
    @include setBackgroundColor(theme-var($background));
    color: theme-var($color);
    border: theme-var($border);
    text-shadow: theme-var($text-shadow);
  }
  @if $padding {
    @include setPadding($padding);
  }
  @if $margin {
    @include setMargin($margin);
  }
  vertical-align: unset;

  &:hover,
  &:active,
  &:focus,
  &:hover:active,
  &:active:focus,
  &:hover:active:focus {
    @include resetBackground;
    @include resetBorder;
    @include resetShadow;
    @include generate-theme {
      @include setBorderRadius($border-radius);
      @include setBackgroundColor(theme-var($background-action));
      color: theme-var($color-action);
      border: theme-var($border-action);
      text-shadow: theme-var($text-shadow-action);
    }
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    @include resetBackground;
    @include resetBorder;
    @include resetShadow;
    @include generate-theme {
      @include setBorderRadius($border-radius);
      @include setBackgroundColor(theme-var($background-disabled));
      color: theme-var($color-disabled);
      border: theme-var($border-disabled);
      text-shadow: theme-var($text-shadow-disabled);
    }
  }
}
