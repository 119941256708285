@import "./../../stylesheets/_definitions";

.UnitAgentButton {
  &__button {
    @include setPadding(2 * getPadding() 4 * getPadding());
    @include setMargin(0 0 2 * getPadding() 0);
    @include setBorderRadius;
    @include setPointerCursor;
    font-weight: bold;
    width: 100%;
    border: 1px solid $borderColor--negative;
    color: $textColorNegative;
    background: transparent;
  }
}
