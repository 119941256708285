@import "./../../stylesheets/_definitions";

.InfoSectionInfoInternet {
  $iconSize: 21px;
  $iconSpace: $iconSize / 2;

  display: inline-block;
  @include setPadding(0px, $right: getPadding());

  &__button {
    @include resetButtonDecorations;
    @include setMargin(0px, $right: $iconSpace);
    width: $iconSize;
    height: $iconSize;
    vertical-align: text-top;

    &:hover {
      text-decoration: underline;
    }

    &--youtube {
      @extend .InfoSectionInfoInternet__button;
      background: url("./youtube.png") no-repeat;

      &:hover {
        background: url("./youtube.png") no-repeat;
      }
    }

    &--wiki {
      @extend .InfoSectionInfoInternet__button;
      @include generate-theme {
        background: url(theme-var(wiki-icon)) no-repeat;
        background-size: $iconSize $iconSize;
      }

      &:hover {
        @include generate-theme {
          background: url(theme-var(wiki-icon-action)) no-repeat;
          background-size: $iconSize $iconSize;
        }
      }
    }

    &--web {
      @extend .InfoSectionInfoInternet__button;
      font-size: $text-size--bigger;
    }
  }
}
