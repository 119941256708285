@import "./../../stylesheets/_definitions";

.UnitTypeMenu {
  &__name {
    @include resetButtonDecorations($color: button-action-text-color);
    white-space: inherit;
    font-size: $leftSection__unitTypeMenuIconFontSize;
  }
}
.UnitTypeMenuItem {
  @include resetSpaces;
  font-size: $leftSection__unitTypeMenuIconFontSize;
  &__name-icon {
    @include setMargin(0px, $right: $leftSection__unitTypeMenuIconPadding);
    font-size: $leftSection__unitTypeMenuIconFontSize;
    //width: 14px;
  }
}
