@import "./../../stylesheets/_definitions";

.Search {
  position: relative;
  text-align: center;
  width: 300px;
  margin: 0px auto;

  &__bar-container {
  }

  &__bar {
    position: relative;
  }

  &__field {
    @include setInputStyle;
    font-weight: bold;

    &--focused {
      @extend .Search__field;
      @include generate-theme {
        border: theme-var(border);
      }
    }

    &--unfocused {
      @extend .Search__field;
      @include setOpacity;
    }
  }
}
