@import "./../../stylesheets/_definitions";

.InfoSectionUsersFilterSorter {
  @include setPadding(0px, $bottom: getPadding());
  width: 100%;

  &__filter {
    width: 100%;
  }

  &__sort-wrapper {
    @include setPadding(0px, $top: 3 * getPadding());
    font-size: $text-size--small;
    vertical-align: bottom;
  }

  &__sort-cell {
    @include generate-theme {
      color: theme-var(text-color-lighter);
    }
    @include setPadding(2 * getPadding());
  }
}
