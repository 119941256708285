@import "./../../stylesheets/_definitions";

.InfoSectionUsersList {
  height: auto;
  overflow: auto;
  width: 100%;

  &__no-users {
    @include generate-theme {
      color: theme-var(text-color-light);
    }
    @include setPadding(0px, $vertical: getPadding());
    font-size: $text-size--big;
    text-align: center;
  }

  &__user {
    position: relative;
  }

  &__cell {
    @include setPadding(getPadding());

    &--thumbnail {
      @extend .InfoSectionUsersList__cell;
    }

    &--info {
      @extend .InfoSectionUsersList__cell;
      text-align: left;
    }

    &--date {
      @extend .InfoSectionUsersList__cell;
    }
  }

  &__image-wrapper {
    width: $modalSection__infoUserThumbnail;
    height: $modalSection__infoUserThumbnail;
  }

  &__icon {
    font-size: $modalSection__infoUserThumbnail + 1px;
  }

  &__name {
    @include resetButtonDecorations(
      $padding: getSpacing(0px, $right: getPadding()),
      $color: text-color
    );
    font-weight: bold;
    font-size: $text-size;
  }

  &__location {
    font-size: $text-size--small;
    @include generate-theme {
      color: theme-var(text-color-light);
    }
  }

  &__message-button {
    @include resetButtonDecorations;
    font-size: $modalSection__infoSideFunctionalButtonSize;
  }

  &__remove-button {
    @include resetButtonDecorations;
    font-size: $modalSection__infoSideFunctionalButtonSize;
    width: $modalSection__infoBottomFunctionalButtonSize;
    height: $modalSection__infoBottomFunctionalButtonSize;
  }
}
