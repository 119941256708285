@import "./../../stylesheets/_definitions";

.AlertBox {
  @include generate-theme {
    @include setOpacity(theme-var(opacity));
  }
  @include aboveTwoLevels;
  position: absolute;
  top: $alertBox__verticalSpace;
  left: $leftSection_width + $alertBox__horizontalSpace;
  right: $alertBox__horizontalSpace;
  overflow: hidden;

  &--hidden-section {
    @extend .AlertBox;
    left: $alertBox__horizontalSpace;
  }
}

.AlertBox {
  &__wrapper {
    overflow: hidden;
    margin-bottom: getMargin();
  }

  &__icon {
    @include setPadding(0px, $right: getPadding());
    font-size: $text-size--bigger;
    vertical-align: text-bottom;
  }

  &__badge {
    @include setMargin(0px, $right: getPadding());
    vertical-align: text-bottom;
  }

  .close {
    @include generate-theme {
      color: theme-var(button-action-text-color);
    }
    right: -3px;
  }
}

.alert {
  @include generate-theme {
    background: theme-var(background-color) none;
  }
}

.alert-warning {
  @include generate-theme {
    color: theme-var(text-color-warning);
  }
}

.badge-warning {
  @extend .AlertBox__badge;
  @include generate-theme {
    @include setBackgroundColor(theme-var(text-color-warning));
  }
}

.alert-danger {
  @include generate-theme {
    color: theme-var(text-color-danger);
  }
}

.badge-danger {
  @extend .AlertBox__badge;
  @include generate-theme {
    @include setBackgroundColor(theme-var(text-color-danger));
  }
}

.alert-success {
  @include generate-theme {
    color: theme-var(text-color-success);
  }
}

.badge-success {
  @extend .AlertBox__badge;
  @include generate-theme {
    @include setBackgroundColor(theme-var(text-color-success));
  }
}

.alert-info {
  @include generate-theme {
    color: theme-var(text-color-info);
  }
}

.badge-info {
  @extend .AlertBox__badge;
  @include generate-theme {
    @include setBackgroundColor(theme-var(text-color-info));
  }
}
